import React, { useState, useEffect, useCallback } from 'react';
import { Html5Qrcode } from "html5-qrcode";
import _ from 'lodash';
import { useAppData } from './context/DataContext';



const Scanner = ({ event_id, style, onScanComplete, attendees, checkedInAttendees, fromQR = false, qrUserId = null, modalOpen }) => {

    useEffect(() => {
        if (fromQR && qrUserId) {
            const mockUrl = `${BASE_URL}/${event_id}?userId=${qrUserId}`;
            processQrCode(mockUrl);
        }
    }, [fromQR, qrUserId]);

    const { users } = useAppData();

    function getEmailFromId(userId) {
        console.log(userId)
        const userIndex = users.findIndex(user => user._id === userId);
        console.log(userIndex)
        console.log(users[userIndex])
        console.log(users[userIndex].email)
        if(userIndex == -1) return ""
        return users[userIndex].email
    }
    const [borderColor, setBorderColor] = useState('transparent');

    const BASE_URL = "https://www.dev.ummahfy.com/event"

    const showBorderFlash = (success) => {
        setBorderColor(success ? '#4CAF50' : '#FF5252');
        setTimeout(() => {
            setBorderColor('transparent');
        }, 500);
    };

    const validateUrl = useCallback((url) => {
        try {
            const parsedUrl = new URL(url);
            const pathParts = parsedUrl.pathname.split('/');
            const scannedEventId = pathParts[pathParts.indexOf('event') + 1];

            if (!url.startsWith(`${BASE_URL}/`) || scannedEventId != event_id) {
                console.log(scannedEventId)
                console.log(event_id)
                return null;
            }

            const userId = parsedUrl.searchParams.get('userId');
            console.log(userId)
            
            return userId || null;
        } catch (error) {
            console.error('Error parsing URL:', error);
            return null;
        }
    }, [event_id]);

    const validateUser = async (userId) => {
        // TODO: Implement user validation logic
        // get User's email from id
        let userEmail = ""
        try {
            userEmail = getEmailFromId(userId)
            console.log(userEmail)
        } catch {
            return false
        }
        // check if email is in registered/approvedAttendees
        return attendees.includes(userEmail); // true
    };

    // Memoize the debounced function so it doesn't recreate on every render
    const processQrCode = useCallback(
        _.debounce(async (decodedText) => {
            try {
                console.log(`QR Code detected: ${decodedText}`);
                let err = ""
                let userEmail = ""

                const userId = validateUrl(decodedText);
                console.log(userId)

                if (!userId) {
                    console.log('Invalid QR code format');
                    err = "Invalid Ticket"
                    showBorderFlash(false);
                } else {
                    console.log(`user id: ${userId}`)
                    const isValid = await validateUser(userId);
                    userEmail = await getEmailFromId(userId)
                    console.log(isValid)
                    if (!isValid) {
                        console.log('User not registered');
                        err = "User Not Found"

                        showBorderFlash(false);
                    }
                }

                console.log(userEmail)

                // TODO: Uncomment and implement callback when ready
                onScanComplete(
                    userEmail,
                    event_id,
                    err
                );

            } catch (error) {
                console.error('Error validating user:', error);
            }
        }, 1000, { leading: true, trailing: false }),
        [validateUrl]
    );

    useEffect(() => {
        let html5QrCode = null;
        const qrCodeScannerId = "qr-code-scanner";

        const startScanner = async () => {
            if (html5QrCode) {
                return; // Scanner already exists
            }

            const qrCodeScannerContainer = document.getElementById(qrCodeScannerId);
            if (!qrCodeScannerContainer) {
                console.error("QR Code scanner container not found.");
                return;
            }

            html5QrCode = new Html5Qrcode(qrCodeScannerId);

            const config = {
                fps: 15,
                qrbox: {
                    width: window.innerWidth,
                    height: window.innerHeight
                }
            };

            try {
                await html5QrCode.start(
                    { facingMode: "environment" },
                    config,
                    async (decodedText, decodedResult) => {
                        await processQrCode(decodedText);
                    },
                    (errorMessage) => {
                        const ignoredErrors = [
                            'No barcode or QR code detected',
                            'No MultiFormat Readers were able to detect the code',
                            'NotFoundException'
                        ];

                        if (!ignoredErrors.some(error => errorMessage.includes(error))) {
                            console.warn(`QR Code scan error: ${errorMessage}`);
                        }
                    }
                );
            } catch (err) {
                console.error("Error initializing QR Code scanner: ", err);
            }
        };

        // Not working
        const stopCamera = async () => {
            if (html5QrCode) {
                try {
                    await html5QrCode.stop();
                    console.log("Camera stopped successfully.");
                } catch (err) {
                    console.error("Error stopping the camera:", err);
                } finally {
                    html5QrCode = null;
                }
            }
        };

        // This is not working cuz when Scanner gets called from the HTML in TicketScanner its not calling it multiple times
        // TODO: Logic needs to get updated
        console.log(modalOpen)
        if (modalOpen) {
            console.log("starting camera")
            startScanner();
        } else {
            console.log("stopping camera")
            stopCamera(); // Stop the camera when the modal is closed
        }


        // Cleanup function
        return () => {
            if (html5QrCode) {
                html5QrCode.stop().catch(err => console.error("Error stopping scanner: ", err));
                html5QrCode = null;
            }
        };
    }, [processQrCode, modalOpen]); // Only depend on processQrCode which is now memoized

    return (
        <div>
            <div id="qr-code-scanner" className="qr-container" style={{
                ...style,
                border: `3px solid ${borderColor}`,
                transition: 'border-color 0.2s ease'
            }}></div>
        </div>
    );
};

export default Scanner;