import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, DatePicker, TimePicker, InputNumber, Button, message, ConfigProvider } from 'antd';
import { DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import FileUpload from './FileUpload';
import LocationSearchInput from "./LocationSearchInput.js";
import ReactQuill from 'react-quill';
import { getEventLoc } from './information.service.js';
import 'react-quill/dist/quill.snow.css';
import TagSelector from './TagSelector.js';
import AgeSelector from './AgeSelector.js'
import GenderSelector from './GenderSelector.js'

const secrets = require('./secrets.js');
const api = secrets.server_url;


dayjs.extend(customParseFormat);

const OrgEditEvent = ({ info, trigger, setTrigger }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(info.img);

    // New state additions from EditEvent
    const [eventData, setEventData] = useState({
        name: info.name,
        organization: info.organization,
        creator: info.creator || "",
        org_type: info.org_type,
        frequency: info.frequency,
        price: info.price,
        age_range: info.age_range,
        gender: info.gender,
        capacity: info.capacity,
        location: info.location,
        latitude: info.latitude,
        longitude: info.longitude,
        tags: info.tags || []
    });


    useEffect(() => {
        if (trigger) {
            form.setFieldsValue({
                name: info.name,
                start_date: dayjs(info.frequency === "Once" ? info.dates[0] : info.dates[info.dateIndex], 'YYYY-MM-DD'),
                end_date: info.dates
                    ? dayjs(info.dates[info.dateIndex], 'YYYY-MM-DD')
                    : dayjs(info.end_datetime.split('T')[0], 'YYYY-MM-DD'),
                start_time: dayjs(info.start_time || info.start_datetime.split('T')[1], 'HH:mm:ss'),
                end_time: dayjs(info.end_time || info.end_datetime.split('T')[1], 'HH:mm:ss'),
                location: info.location,
                virtual_link: info.virtual_link,
                description: info.description,
                capacity: info.capacity,
                org_type: info.org_type || 'N/A',
                age_range: info.age_range || 'N/A',
                gender: info.gender || 'N/A',
                frequency: info.frequency || 'N/A',
                price: info.price || 0,
                tags: info.tags || []
            });
            setImageUrl(info.img);
        }
    }, [trigger, info, form]);

    const uploadImage = async (imageFile) => {
        try {
            // Create FormData object
            const formData = new FormData();
            formData.append('image', imageFile);

            // Make the request
            const response = await axios.post(`${api}uploadImage`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true, // Important for CORS with credentials
            });

            // Response will contain imageUrl if successful
            console.log('Upload successful! Image URL:', response.data.imageUrl);
            return response.data.imageUrl;

        } catch (error) {
            console.error('Error uploading image:', error.response?.data?.message || error.message);
            throw error;
        }
    };


    const handlePlaceSelected = async (place) => {
        try {
            console.log('Place selected:', place); // Debug log

            const formData = form.getFieldsValue();
            form.setFieldsValue({ ...formData, location: place.formatted_address });

            if (place) {
                const coords = await getEventLoc(place.formatted_address);
                console.log('Coordinates received:', coords); // Debug log

                setEventData(prev => ({
                    ...prev,
                    latitude: coords.latitude,
                    longitude: coords.longitude,
                    location: place.formatted_address
                }));

                // Also update the form values
                form.setFieldsValue({
                    location: place.formatted_address
                });
            }
        } catch (error) {
            console.error('Error handling place selection:', error);
            message.error('Failed to get location coordinates');
        }
    };

    const handleTagChange = (selectedTags) => {
        form.setFieldsValue({ tags: selectedTags });
    };

    const handleAgeChange = (selectedAge) => {
        form.setFieldsValue({ age_range: selectedAge });
    };

    const handleGenderChange = (selectedGender) => {
        form.setFieldsValue({ gender: selectedGender });
    };

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            setLoading(true);

            // Construct the datetime strings properly
            const startDate = values.start_date.format('YYYY-MM-DD')
            const endDate = values.end_date.format('YYYY-MM-DD')
            const startTime = values.start_time.format('HH:mm:ss') + '.000'
            const endTime = values.end_time.format('HH:mm:ss') + '.000'


            const updatedEvent = {
                ...info,
                name: values.name,
                location: values.location,
                virtual_link: values.virtual_link,
                description: values.description,
                capacity: values.capacity,
                img: imageUrl,
                price: values.price,
                age_range: values.age_range === 'N/A' ? null : values.age_range,
                gender: values.gender === 'N/A' ? null : values.gender,
                // frequency: values.frequency === 'N/A' ? null : values.frequency,
                latitude: eventData.latitude,
                longitude: eventData.longitude,
                dates: [startDate, endDate],
                start_time: startTime,
                end_time: endTime,
                tags: values.tags
            };

            console.log(updatedEvent)

            // Remove any undefined or null values
            Object.keys(updatedEvent).forEach(key =>
                (updatedEvent[key] === undefined || updatedEvent[key] === null) && delete updatedEvent[key]
            );

            console.log('Sending update:', updatedEvent); // Debug log

            const response = await axios.put(
                `${process.env.REACT_APP_SERVER_URL}events/${info.event_id}`,
                updatedEvent,
                {
                    headers: {
                        'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data) {
                message.success('Event updated successfully');
                setTrigger(false);
                window.location.reload();
            } else {
                throw new Error('No response from server');
            }

        } catch (error) {
            console.error('Error updating event:', error);
            if (error.response) {
                console.error('Server response:', error.response.data);
                message.error(error.response.data.message || 'Failed to update event');
            } else if (error.errorFields) {
                message.error('Please fill in all required fields correctly');
            } else {
                message.error('Failed to update event. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteEvent = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_SERVER_URL}events/${info.event_id}`, {
                headers: {
                    'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
                }
            });
            message.success('Event deleted successfully');
            window.location.href = '/events';
        } catch (error) {
            console.error('Error deleting event:', error);
            message.error('Failed to delete event');
        }
    };

    const handleImageUpload = async (file) => {
        try {
            const fileName = `event-${info.event_id}-${Date.now()}`;
            const uploadedUrl = await uploadImage(file, fileName);
            setImageUrl(uploadedUrl);
            message.success('Image uploaded successfully');
            return false;
        } catch (error) {
            console.error('Error uploading image:', error);
            message.error('Failed to upload image');
            return false;
        }
    };

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            ['link'],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link',
    ];

    const handleApproveEvent = async () => {
        try {
            // Update event approval status
            const response = await axios.put(
                `${process.env.REACT_APP_SERVER_URL}events/${info.event_id}`,
                { isApproved: true },
                {
                    headers: {
                        'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY,
                        'Content-Type': 'application/json'
                    }
                }
            );

            // If event creator exists, send notification email
            if (eventData.creator) {
                try {
                    await axios.post(
                        `${process.env.REACT_APP_SERVER_URL}sendEmail`,
                        {
                            email: eventData.creator,
                            eventName: eventData.name,
                            eventURL: window.location.href,
                            type: "Event Approved"
                        },
                        {
                            headers: {
                                'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY,
                                'Content-Type': 'application/json'
                            }
                        }
                    );
                } catch (emailError) {
                    console.error('Error sending approval email:', emailError);
                    // Continue execution even if email fails
                }
            }

            message.success('Event approved successfully');
            window.location.href = '/adminPanel';

        } catch (error) {
            console.error('Error approving event:', error);
            message.error('Failed to approve event');
        }
    };

    return (
        <Modal
            title="Edit Event Details"
            className='edit-event-modal'
            style={{ top: 20 }}
            open={trigger}
            onOk={handleSubmit}
            onCancel={() => setTrigger(false)}
            confirmLoading={loading}
            width={800}
            bodyStyle={{
                maxHeight: 'calc(100vh - 200px)',  // Adjust this value as needed
                overflow: 'auto',
                //  paddingRight: '20px'  Add some padding for the scrollbar
            }}

            footer={[
                <div key="footer-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        {!info.isApproved && (
                            <Button key="approve" icon={<CheckOutlined />} onClick={handleApproveEvent}>
                                Approve Event
                            </Button>
                        )}
                    </div>
                    <div style={{ display: 'flex', gap: '8px' }}>
                        <Button key="delete" danger icon={<DeleteOutlined />} onClick={handleDeleteEvent}>
                            Delete Event
                        </Button>
                        <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
                            Save Changes
                        </Button>
                    </div>
                </div>
            ]}
        >


            <Form
                form={form}
                layout="vertical"
                className="edit-event-form"
            >


                <Form.Item >
                    <FileUpload
                        onFileSelect={handleImageUpload}
                        existingImage={info.img} // Pass the existing image URL
                    />
                </Form.Item>

                <Form.Item
                    name="name"
                    label="Event Name"
                    rules={[{ required: true, message: 'Please enter event name' }]}
                >
                    <Input style={{ height: '36px', borderRadius: '4px', borderColor: '#e6e6e6' }} />
                </Form.Item>

                <div style={{ display: 'flex', gap: '16px' }}>


                    <Form.Item
                        name="start_date"
                        label="Start Date"
                        rules={[{ required: true, message: 'Please select start date' }]}
                        style={{ flex: 1 }}
                    >
                        <DatePicker style={{ width: '100%', height: '36px', borderRadius: '4px', borderColor: '#e6e6e6' }}
                            format="MM/DD/YY"
                        />
                    </Form.Item>

                    <Form.Item
                        name="start_time"
                        label="Start Time"
                        rules={[{ required: true, message: 'Please select start time' }]}
                        style={{ flex: 1 }}
                    >
                        <TimePicker use12Hours format="h:mm a" style={{ width: '100%', height: '36px', borderRadius: '4px', borderColor: '#e6e6e6' }} />
                    </Form.Item>
                </div>

                <div style={{ display: 'flex', gap: '16px' }}>
                    <Form.Item
                        name="end_date"
                        label="End Date"
                        rules={[{ required: true, message: 'Please select end date' }]}
                        style={{ flex: 1 }}
                    >
                        <DatePicker style={{ width: '100%', height: '36px', borderRadius: '4px', borderColor: '#e6e6e6' }}
                            format="MM/DD/YY"
                        />
                    </Form.Item>

                    <Form.Item
                        name="end_time"
                        label="End Time"
                        rules={[{ required: true, message: 'Please select end time' }]}
                        style={{ flex: 1 }}
                    >
                        <TimePicker use12Hours format="h:mm a" style={{ width: '100%', height: '36px', borderRadius: '4px', borderColor: '#e6e6e6' }} />
                    </Form.Item>
                </div>
                {!info.virtual_link ? (
                    <Form.Item
                        name="location"
                        label="Location"
                    >
                        <LocationSearchInput
                            initialValue={info.location}
                            onPlaceSelected={handlePlaceSelected}
                            currentLoc={info.location}
                        />
                    </Form.Item>
                ) : (
                    <Form.Item
                        name="virtual_link"
                        label="Virtual Link"
                    >
                        <Input
                            placeholder="Enter virtual meeting link"
                            style={{
                                width: '100%',
                                height: '36px',
                                borderRadius: '4px',
                                borderColor: '#e6e6e6'
                            }}
                        />
                    </Form.Item>
                )}

                <ConfigProvider
                    theme={{
                        components: {
                            InputNumber: {
                                activeShadow: 'none',
                                controlOutline: 'none',
                            },
                        },
                    }}
                >
                    <div style={{ display: 'flex', gap: '16px' }}>
                        <Form.Item
                            name="price"
                            label="Price"
                            style={{ flex: 1 }}
                            initialValue={0}
                        >
                            <InputNumber
                                style={{
                                    width: '100%',
                                    borderRadius: '4px',
                                    height: '36px',
                                    fontFamily: 'Outfit',
                                    borderColor: '#e6e6e6'

                                }}
                                controls={false}
                                prefix="$"
                                min={0}
                                stringMode={false}
                                keyboard={true}
                                placeholder="0.00"
                                parser={value => value.replace(/[^0-9.]/g, '')}
                                disabled
                            />
                        </Form.Item>

                        <Form.Item
                            name="capacity"
                            label="Capacity"
                            style={{ flex: 1 }}
                            initialValue={0}
                        >
                            <InputNumber
                                style={{
                                    width: '100%',
                                    borderRadius: '4px',
                                    height: '36px',
                                    fontFamily: 'Outfit',
                                    borderColor: '#e6e6e6'
                                }}
                                controls={false}
                                min={0}
                                stringMode={false}
                                keyboard={true}
                                placeholder="0"
                                parser={value => value.replace(/[^0-9]/g, '')}
                            />
                        </Form.Item>
                    </div>
                </ConfigProvider>

                <Form.Item
                    name="description"
                    label="Description"
                    rules={[{ required: true, message: 'Please enter event description' }]}
                >
                    <ReactQuill
                        theme="snow"
                        className="my-custom-quill"
                        modules={modules}
                        formats={formats}
                    />
                </Form.Item>


                <div style={{
                    display: 'flex',
                    gap: '16px',
                    flexWrap: 'wrap',
                    minWidth: '100%'
                }}>
                    <Form.Item
                        name="gender"
                        style={{
                            flex: '1 1 calc(50% - 8px)',
                            minWidth: '200px'  // Adjust this value based on your needs
                        }}
                    >
                        <GenderSelector
                            style={{ width: '100%' }}
                            onChange={handleGenderChange}
                            defaultValue={info.gender || null}
                        />
                    </Form.Item>

                    <Form.Item
                        name="age_range"
                        style={{
                            flex: '1 1 calc(50% - 8px)',
                            minWidth: '200px'  // Adjust this value based on your needs
                        }}
                    >
                        <AgeSelector
                            style={{ width: '100%' }}
                            onChange={handleAgeChange}
                            defaultValue={info.age_range || null}
                        />
                    </Form.Item>
                </div>

                <Form.Item
                    name="tags"

                >
                    <TagSelector
                        onChange={handleTagChange}
                        defaultValue={info.tags || []}
                    />
                </Form.Item>

            </Form>
        </Modal>
    );
};

export default OrgEditEvent;