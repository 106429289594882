import React from "react";
import moment from 'moment';

function EventCard(props) {

  function capitalizeFirstLetter(string) {

    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  //console.log("screen size", props.screenSize)

  const formatPrice = (price) => {
    // console.log("check if price is 0 or free", price)
    // Check if price is 0 or "Free" and return "Free" directly
    if (price === "0" || price === "Free") {
      return "Free";
    }

    // Convert price to string if it's a number
    let priceStr = price.toString();

    // Add '$' if it's not already present
    if (!priceStr.startsWith("$")) {
      priceStr = `$${priceStr}`;
    }

    return priceStr;
  };


  const formatTime = (time) => {
    const momentTime = moment(time, 'HH:mm:ss.SSS');
    const minutes = momentTime.minutes();
    let hours = momentTime.hours();
    const ampm = hours >= 12 ? 'pm' : 'am';

    // Convert 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // If hours is 0, make it 12

    // Pad minutes with leading zero if less than 10
    const paddedMinutes = minutes.toString().padStart(2, '0');

    return minutes === 0
      ? `${hours}${ampm}` // shows "6pm" when no minutes
      : `${hours}:${paddedMinutes}${ampm}`; // shows "6:04pm" when has minutes
  };

  return (
    <div className="e-card-home">
      <div className="e-img-mask">
        <img src={props.imgurl} />
      </div>

      <div className="e-right">
        <div className="e-event">
          {props.event}
        </div>

        <div className="e-masjid"> {props.masjid} </div>

        <div className="e-date">
          <p>

            {/* {console.log(props.date, "this is the props.date value HERE")} */}
            {props.date === "Mon, Jan 1  •  12 am"
              ? `${moment(props.altdate).format('ddd, MMM D')} · ${formatTime(props.time)}`
              : props.date
            }
          </p>
        </div>



        <div className="e-desc">
          <div dangerouslySetInnerHTML={{ __html: props.description }} />
        </div>

        {/* {console.log(props, "this is the info array for event cards")} */}
        <div className="badge-grp bdgrp-evsh">
          {props.frequency && props.frequency.toLowerCase() !== 'once' && (
            <div className="badge time-badge">
              <p>{capitalizeFirstLetter(props.frequency)}</p>
            </div>
          )}

          {props.age && props.age !== 'all' && (
            <div className="badge age-badge">
              <p>{capitalizeFirstLetter(props.age)}</p>
            </div>
          )}

          {props.gender && props.gender !== ('all' || '') && (
            <div className="badge gender-badge">
              <p>{capitalizeFirstLetter(props.gender)}</p>
            </div>
          )}

          <div className="badge price-badge">
            <p>{formatPrice(props.price)}</p>
          </div>

          {props.tags && props.tags.map((tag, index) => (
            <div key={index} className="badge tag-badge">
              <p>{tag}</p>
            </div>
          ))}
        </div>

        <div className="e-bottom">
          {/* <div class="e-attendees">
            <IconUmmahfy style={{ width: '16px', marginRight: '-3px', }} />
            <IconUmmahfyTwo style={{ width: '16px', marginRight: '-3px', fill: '#fff' }} />
            <IconUmmahfyThree style={{ width: '16px', marginRight: '0px', }} />
            <p style={{ marginLeft: '4px' }}> {props.registered.length} going </p>
          </div> */}

        </div>
      </div>

    </div>

  )

}

export default EventCard;


/* <div className="badge-group e-badges">
  {props.frequency && props.frequency !== 'once' && (
    <div className="badge time-badge">
      <p>{capitalizeFirstLetter(props.frequency)}</p>
    </div>
  )}
  {props.age && props.age !== 'all' && (
    <div className="badge age-badge">
      <p>{capitalizeFirstLetter(props.age)}</p>
    </div>
  )}
  {props.gender && props.gender !== 'all' && (
    <div className="badge gender-badge">
      <p>{capitalizeFirstLetter(props.gender)}</p>
    </div>
  )}
  {props.price && (
    <div className="badge price-badge">
      <p>{formatPrice(props.price)}</p>
    </div>
  )}
</div> */