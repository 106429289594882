import React from 'react';
import { Modal, ConfigProvider } from 'antd';
import ShareButton from "./ShareButton";

function RequestRegistrationModal(props) {
    const ensureHttps = (url) => {
        if (!url.startsWith("https://")) {
            return "https://" + url;
        }
        return url;
    };

    const externalLink = ensureHttps(props.info.tickets_link);

    const shouldRender =
        props.info.tickets_link !== null &&
        props.info.tickets_link !== "N/A" &&
        props.info.tickets_link !== "";

    const handleClose = async () => {
        await props.setTrigger(false);
        window.location.reload();
    };

    return (
        <ConfigProvider
        theme={{
            components: {
                Modal: {
                    titleFontSize: 24,

                },
            },
        }}
    >
        <Modal
            open={props.trigger}
            onCancel={handleClose}
            footer={null}
            width={400}
            centered
            title= "Approval Requested!"
            // {
            //     <div className="title-icon">
            //         <span className="rm-title">You're Going!</span>
            //         <SuccessIcon className="success-icon" />
            //     </div>
            // }
            className="registration-modal"
        >
            {shouldRender ? (
                <>
                    <p className="rm-button-label">
                        A confirmation email has been sent to you! This event requires further registration:
                    </p>
                    <div className="next-step-share">
                        <a 
                            href={externalLink} 
                            className="next-step-link" 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            <button className="next-step-registration">
                                Click Here to Continue
                            </button>
                        </a>
                        <ShareButton 
                            buttontext="" 
                            styling="ns-register-modal-share" 
                            iconstyling="ns-rm-share-button-icon" 
                        />
                    </div>
                </>
            ) : (
                <>
                    <p className="rm-subtext">
                    Your registration is pending approval from the event host.
                    </p>
                    {/* <ShareButton 
                        buttontext="Copy Link" 
                        styling="register-modal-share" 
                        iconstyling="rm-share-button-icon" 
                    /> */}
                </>
            )}
        </Modal>
        </ConfigProvider>
    );
}

export default RequestRegistrationModal;