// TagSelector.jsx
import React, { useState } from 'react';

const TagSelector = ({ onChange, defaultValue }) => {
  const [selectedTags, setSelectedTags] = useState(defaultValue || []);

  const tags = [
    'Religious', 'Social', 'Professional', 'Charity', 'Activism', 'Sports', 'Art',
    'Volunteer', 'Family', 'Education', 'Wellness', 'Revert', 'Travel', 'Virtual'
  ];

  const toggleTag = (tag) => {
    const newSelectedTags = selectedTags.includes(tag)
      ? selectedTags.filter(t => t !== tag)
      : [...selectedTags, tag];

    setSelectedTags(newSelectedTags);
    if (onChange) {
      onChange(newSelectedTags);
    }
  };

  return (
    <div className="tag-selector-container">
      <p className="tag-selector-title">
        Select Tags <span className="optional-text">(optional)</span>
      </p>
      <div className="tags-container">
        {tags.map(tag => (
          <div
            key={tag}
            onClick={() => toggleTag(tag)}
            className={`tag-option ${selectedTags.includes(tag) ? 'selected' : ''}`}
          >
            {tag}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TagSelector;