import React, { useState } from 'react';
import { Modal, ConfigProvider, Button, Input } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useAppData } from './context/DataContext';
import ReactQuill from 'react-quill';
import axios from 'axios';

// Constants
const api = process.env.REACT_APP_SERVER_URL;

const QUILL_MODULES = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    ['link'],
    ['clean']
  ],
};

const QUILL_FORMATS = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link',
];

const MODAL_THEME = {
  components: {
    Modal: {
      titleFontSize: 20,
    },
  },
};

const BUTTON_THEME = {
  token: {
    colorPrimary: '#002244',
  },
};

// Styles
const styles = {
  modal: {
    top: 20,
  },
  modalBody: {
    maxHeight: 'calc(100vh - 225px)',
    overflow: 'auto',
  },
  messageButton: {
    boxShadow: 'none',
  },
  attendeeHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  attendeeAvatar: {
    flexShrink: 0,
  },
  nameEmailDiv: {
    flexGrow: 1,
  },
};

const AttendeesModal = ({
  isVisible,
  onCancel,
  info,
  dateIndex,
  getAttendeeDisplay,
  approvedAttendees,
  onApprovalChange,
  registeredIndex,
  handleRegistrationApproval
}) => {
  // State
  const { users } = useAppData();
  const [isMessageModalVisible, setIsMessageModalVisible] = useState(false);
  const [wasAttendeesModalVisible, setWasAttendeesModalVisible] = useState(false);
  const [text, setText] = useState('');
  const [subject, setSubject] = useState('');

  // Helper Functions
  const getNameFromEmail = (inputEmail) => {
    const userIndex = users.findIndex(user => user.email === inputEmail);
    return userIndex !== -1 ? users[userIndex].username : inputEmail;
  };

  const toggleApproval = (email) => {
    if (!approvedAttendees.includes(email)) {
      handleRegistrationApproval(email);
    }

    if (onApprovalChange) {
      const newApprovedList = approvedAttendees.includes(email)
        ? approvedAttendees.filter(e => e !== email)
        : [...approvedAttendees, email];
      onApprovalChange(newApprovedList);
    }
  };

  // Modal Handlers
  const showMessageModal = () => {
    setWasAttendeesModalVisible(true);
    onCancel();
    setIsMessageModalVisible(true);
  };

  const handleMessageModalCancel = () => {
    setIsMessageModalVisible(false);
    if (wasAttendeesModalVisible) {
      setWasAttendeesModalVisible(false);
      setTimeout(() => onCancel(false), 100);
    }
  };

  // Form Handlers
  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleDescChange = (content) => {
    setText(content);
  };

  // API Functions
  const sendMessageBlast = async () => {
    const payload = {
      email: info.requireApproval ? approvedAttendees[registeredIndex] : info.registered[registeredIndex],
      eventName: info.name,
      eventURL: window.location.href,
      type: "Message Blast",
      eventLocation: info.location,
      dateTime: new Date((info.dates[registeredIndex].split('T'))[0] + 'T00:00:00').toLocaleDateString('en-US')
        + " @ " +
        new Date(`2000-01-01T${info.start_time}`).toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        }),
      customSubject: subject,
      customText: text
    };

    try {
      const response = await axios.post(`${api}sendEmail`, payload, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        }
      });
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };

  // Render Helper Functions
  const renderAttendeeList = () => {
    if (!info?.registered?.[registeredIndex] || info.registered[registeredIndex].length === 0) {
      return (
        <div className="no-attendees">
          <p className="no-attendees-text">No attendees registered yet</p>
        </div>
      );
    }

    return info.registered[registeredIndex].map((email, index) => {
      const isApproved = approvedAttendees.includes(email);
      const formResponse = info.customFormResponses?.[email];

      return (
        <div key={index} className="attendee-card">
          <div className="attendee-header" style={styles.attendeeHeader}>
            <div className="attendee-avatar" style={styles.attendeeAvatar}>
              <span className="avatar-text">
                {getNameFromEmail(email).charAt(0).toUpperCase()}
              </span>
            </div>
            <div className="name-email-div" style={styles.nameEmailDiv}>
              <span><b>{getNameFromEmail(email)}</b></span>
              <span>{email}</span>
            </div>
          </div>
          {formResponse && (
            <div className="form-response-container">
              <b>Form Responses:</b>
              <ul className="form-response-list">
                {Object.entries(formResponse).map(([question, response], idx) => (
                  <li key={idx}>
                    <b>Q{idx + 1}:</b> {response}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {info.requireApproval && (
            <div className="approval-buttons">
              <div
                className={`status-badge ${isApproved ? 'approved' : 'approve'}`}
                onClick={() => toggleApproval(email)}
              >
                {isApproved ? 'Approved' : 'Approve'}
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <ConfigProvider theme={MODAL_THEME}>
      <Modal
        className="ce-modal"
        title={`Attendees List (${getAttendeeDisplay(info, dateIndex)})`}
        open={isVisible}
        style={styles.modal}
        onCancel={onCancel}
        bodyStyle={styles.modalBody}
        footer={
          <ConfigProvider theme={BUTTON_THEME}>
            <Button type="primary" onClick={showMessageModal} style={styles.messageButton}>
              Message Blast
            </Button>
          </ConfigProvider>
        }
        closable={true}
        maskClosable={true}
        centered
      >
        <div className="attendees-container">
          {renderAttendeeList()}
        </div>
      </Modal>

      <Modal
        title="Message Blast"
        open={isMessageModalVisible}
        onCancel={handleMessageModalCancel}
        footer={[
          <Button key="cancel" onClick={handleMessageModalCancel}>
            Cancel
          </Button>,
          <Button 
            key="submit" 
            type="primary" 
            onClick={() => {
              sendMessageBlast();
              handleMessageModalCancel();
            }}
          >
            Send Message
          </Button>
        ]}
        centered
      >
        <div>
          <Input
            className="subject-input"
            placeholder="Subject"
            value={subject}
            onChange={handleSubjectChange}
          />
        </div>

        <div className="description-input1">
          <ReactQuill
            theme="snow"
            className="my-custom-quill"
            modules={QUILL_MODULES}
            formats={QUILL_FORMATS}
            value={text}
            onChange={handleDescChange}
          />
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default AttendeesModal;