// FormBuilder.jsx
import React, { useState } from 'react';
import { Modal, Button, Form, Input, Select, Space, Card, Tooltip, ConfigProvider, Switch } from 'antd';
import { PlusOutlined, DeleteOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const { Option } = Select;

const FormBuilder = ({ isVisible, onCancel, onSave }) => {
    const [form] = Form.useForm();
    const [questions, setQuestions] = useState([]);

    const questionTypes = [
        { value: 'short_text', label: 'Text' },
        // { value: 'long_text', label: 'Long Text' },
        { value: 'multiple_choice', label: 'Multiple Choice' },
        { value: 'checkbox', label: 'Checkboxes' },
        { value: 'dropdown', label: 'Dropdown' }
    ];

    const addQuestion = () => {
        setQuestions([
            ...questions,
            {
                id: Date.now(),
                type: 'short_text',
                question: '',
                required: false,
                options: []
            }
        ]);
    };

    const removeQuestion = (questionId) => {
        setQuestions(questions.filter(q => q.id !== questionId));
    };

    const moveQuestion = (index, direction) => {
        const newQuestions = [...questions];
        const temp = newQuestions[index];
        newQuestions[index] = newQuestions[index + direction];
        newQuestions[index + direction] = temp;
        setQuestions(newQuestions);
    };

    const updateQuestion = (questionId, field, value) => {
        setQuestions(questions.map(q =>
            q.id === questionId ? { ...q, [field]: value } : q
        ));
    };

    const addOption = (questionId) => {
        setQuestions(questions.map(q => {
            if (q.id === questionId) {
                return {
                    ...q,
                    options: [...q.options, { id: Date.now(), value: '' }]
                };
            }
            return q;
        }));
    };

    const removeOption = (questionId, optionId) => {
        setQuestions(questions.map(q => {
            if (q.id === questionId) {
                return {
                    ...q,
                    options: q.options.filter(opt => opt.id !== optionId)
                };
            }
            return q;
        }));
    };

    const updateOption = (questionId, optionId, value) => {
        setQuestions(questions.map(q => {
            if (q.id === questionId) {
                return {
                    ...q,
                    options: q.options.map(opt =>
                        opt.id === optionId ? { ...opt, value } : opt
                    )
                };
            }
            return q;
        }));
    };

    const handleSave = () => {
        // Transform the questions array to match the MongoDB schema format
        const formattedQuestions = questions.map(question => ({
            question: question.question,
            required: question.required
        }));
    
        // Pass the formatted questions to onSave
        onSave( formattedQuestions );
        onCancel();
    };

    return (
        <ConfigProvider
            theme={{
                components: {
                    Modal: {
                        titleFontSize: 20,
                    },
                },
            }}
        >
            <Modal
                title="Form Builder"
                style={{ top: 20 }}
                open={isVisible}
                onCancel={onCancel}
                width={800}
                bodyStyle={{
                    maxHeight: 'calc(100vh - 225px)',
                    overflow: 'auto',
                  }}
                footer={[
                    <Button key="cancel" onClick={onCancel}>
                        Cancel
                    </Button>,
                    <Button key="save" type="primary" onClick={handleSave}>
                        Save Form
                    </Button>
                ]}
            >
                <Space direction="vertical" style={{ width: '100%' }}>
                    {questions.map((question, index) => (
                        <Card
                            key={question.id}
                            style={{ marginBottom: 16,}}
                            extra={
                                <Space>
                                    {index > 0 && (
                                        <Tooltip title="Move Up">
                                            <Button
                                                icon={<ArrowUpOutlined />}
                                                onClick={() => moveQuestion(index, -1)}
                                            />
                                        </Tooltip>
                                    )}
                                    {index < questions.length - 1 && (
                                        <Tooltip title="Move Down">
                                            <Button
                                                icon={<ArrowDownOutlined />}
                                                onClick={() => moveQuestion(index, 1)}
                                            />
                                        </Tooltip>
                                    )}
                                    <Tooltip title="Delete Question">
                                        <Button
                                            icon={<DeleteOutlined />}
                                            onClick={() => removeQuestion(question.id)}
                                            danger
                                        />
                                    </Tooltip>
                                </Space>
                            }
                        >
                            <Space direction="vertical" style={{ width: '100%' }}>
                                
                                {/* COMMENTING THIS OUT UNTIL WE DECIDE WHATS NECESSARY HERE */}
                                {/* <Select
                                    value={question.type}
                                    style={{ width: 200 }}
                                    onChange={(value) => updateQuestion(question.id, 'type', value)}
                                >
                                    {questionTypes.map(type => (
                                        <Option key={type.value} value={type.value}>
                                            {type.label}
                                        </Option>
                                    ))}
                                </Select> */}

                                <Input
                                    placeholder="Enter your question"
                                    value={question.question}
                                    onChange={(e) => updateQuestion(question.id, 'question', e.target.value)}
                                    style={{ 
                                        height: '36px', 
                                        borderRadius: '4px',
                                        fontFamily: 'Outfit',
                                    }}
                                />

                                {['multiple_choice', 'checkbox', 'dropdown'].includes(question.type) && (
                                    <div style={{ marginLeft: 24 }}>
                                        {question.options.map((option) => (
                                            <Space key={option.id} style={{ marginBottom: 8 }}>
                                                <Input
                                                    placeholder="Option text"
                                                    value={option.value}
                                                    onChange={(e) => updateOption(question.id, option.id, e.target.value)}
                                                />
                                                <Button
                                                    icon={<DeleteOutlined />}
                                                    onClick={() => removeOption(question.id, option.id)}
                                                    danger
                                                />
                                            </Space>
                                        ))}
                                        <Button
                                            type="dashed"
                                            onClick={() => addOption(question.id)}
                                            icon={<PlusOutlined />}
                                        >
                                            Add Option
                                        </Button>
                                    </div>
                                )}

                                <Form.Item
                                    valuePropName="checked"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Space align="center">
                                    <label className="required-toggle" style={{cursor:"pointer"}}>
                                        <Switch
                                            checked={question.required}
                                            onChange={(checked) => updateQuestion(question.id, 'required', checked)}
                                        />
                                        <span style={{marginLeft:'8px'}}>{question.required ? 'Required' : 'Optional'}</span>
                                        </label>
                                    </Space>
                                </Form.Item>
                            </Space>
                        </Card>
                    ))}

                    <Button
                        type="dashed"
                        onClick={addQuestion}
                        style={{ width: '100%' }}
                        icon={<PlusOutlined />}
                    >
                        Add Question
                    </Button>
                </Space>
            </Modal>
        </ConfigProvider>
    );
};

export default FormBuilder;