// AgeSelector.jsx
import React, { useState } from 'react';

const AgeSelector = ({ onChange, defaultValue }) => {
  const [selectedAge, setSelectedAge] = useState(defaultValue || null);
  const ageOptions = ['Youth', 'HS/College', 'Adult'];

  const toggleAge = (age) => {
    const newSelectedAge = selectedAge === age ? null : age;
    setSelectedAge(newSelectedAge);
    if (onChange) {
      onChange(newSelectedAge);
    }
  };

  return (
    <div className="age-selector-container">
      <p className="age-selector-title">
        Age <span className="optional-text">(optional)</span>
      </p>
      <div className="age-options-container">
        {ageOptions.map(age => (
          <div
            key={age}
            onClick={() => toggleAge(age)}
            className={`age-option ${selectedAge === age ? 'selected' : ''}`}
          >
            {age}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AgeSelector;
