import React from 'react';
import { Modal, Button, Form, Input, ConfigProvider } from 'antd';
import axios from "axios";


const secrets = require("./secrets.js");
const api = secrets.server_url;

const CustomFormModal = ({ isOpen, onClose, onSubmit, info, isRegistered, loggedInEmail, isStripe }) => {



  console.log('props:', onSubmit)

  const [form] = Form.useForm();

  const handleModalCancel = () => {
    form.resetFields();
    onClose();
  };

  const saveResponses = async () => {
    const formValues = form.getFieldsValue();
    // Map over the questions to preserve order and handle empty responses
    const responsesArray = info.customFormQuestions.map(question =>
      formValues[question.question] || ''
    );

    const updatedFormResponses = info.customFormResponses || {}
    updatedFormResponses[loggedInEmail.data] = responsesArray

    try {
      const response = await axios.put(
        `${api}events/${info.event_id}`,
        {
          customFormResponses: updatedFormResponses
        },
        {
          headers: {
            'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY,
          }
        }
      );
      console.log('Success:', response);
    } catch (error) {
      console.error('Error response:', error.response?.data);
      console.error('Full error:', error);
    }

  };

  const editResponses = async () => {
    try {
      await form.validateFields();
      await saveResponses();
      // Refresh the page after everything is complete
      window.location.reload();
    } catch (error) {
      console.log('Validate Failed:', error);
    }
  };

  const handleSubmitForm = async () => {
    try {
      await form.validateFields();
      await saveResponses();
      await onSubmit(info.event_id);
      // Refresh the page after everything is complete
      if(!isStripe)
        window.location.reload();
    } catch (error) {
      console.log('Validate Failed:', error);
    }
  };

  console.log(info)

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            titleFontSize: 20,
          },
        },
      }}
    >
      <Modal
        className="ce-modal"
        title="Questions"
        open={isOpen}
        onCancel={handleModalCancel}
        closable={true}
        maskClosable={true}
        bodyStyle={{
          maxHeight: 'calc(100vh - 225px)',
          overflow: 'auto',
        }}
        footer={[
          <Button
            key="cancel"
            onClick={handleModalCancel}
          >
            Cancel
          </Button>,
          <Button
            key="continue"
            type="primary"
            onClick={isRegistered ? editResponses : handleSubmitForm}
          >
            {isRegistered ? 'Save' : 'Continue'}
          </Button>
        ]}
        centered
      >
        <Form
          form={form}
          layout="vertical"
        >
          {info.customFormQuestions.map((question, index) => {
            // Check if user has previous responses and handle if they don't
            const userResponses = info.customFormResponses?.[loggedInEmail.data] || [];
            const previousResponse = userResponses[index] || '';

            return (
              <Form.Item
                key={question.question}
                label={question.question}
                name={question.question}
                rules={[{ required: question.required, message: "This question is required" }]}
                initialValue={previousResponse}
              >
                <Input style={{ fontFamily: 'Outfit' }}/>
              </Form.Item>
            );
          })}
        </Form>
      </Modal>
    </ConfigProvider>
  );
};

export default CustomFormModal;