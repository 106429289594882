import React, { useState } from 'react';
import { Button, ConfigProvider, Modal, Segmented } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import { useAppData } from './context/DataContext';
import Scanner from "./Scanner";
import axios from 'axios';
import './TicketScanner.css';
import { ReactComponent as CloseIcon } from './CloseIcon.svg';


const TicketScanner = ({ info, registeredIndex, fromQR = false, qrUserId = null, isOpen, onOpen, onClose  }) => {
    const [attendeeFilter, setAttendeeFilter] = useState('All');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(''); //Types: "", "Already Checked In", "Invalid Ticket", "User Not Found"
    

    const [checkedInAttendees, setCheckedInAttendees] = useState(info.checkedInAttendees[registeredIndex] || [])
    const attendees = info.requireApproval ? info.approvedAttendees[registeredIndex] : info.registered[registeredIndex]

    const { users } = useAppData();

    function getNameFromEmail(inputEmail) {
        const userIndex = users.findIndex(user => user.email === inputEmail);
        return userIndex !== -1 ? users[userIndex].username : inputEmail;
    }

    const handleOpenModal = () => {
        onOpen();
    };

    const handleCloseModal = () => {
        onClose();
    };

    const renderAttendeesList = () => {
        if (!attendees || attendees.length === 0) {
            return (
                <div className="no-attendees">
                    <p className="no-attendees-text">{`No attendees ${info.requireApproval ? 'approved' : 'registered'} yet`}</p>
                </div>
            );
        }

        const filteredAttendees = attendees.filter(email => {
            if (attendeeFilter === 'Checked In') {
                return checkedInAttendees.includes(email);
            }
            if (attendeeFilter === 'Pending') {
                return !checkedInAttendees.includes(email);
            }

            // if attendeeFilter is 'All' or any other value
            return true;
        });

        return (
            <div className="attendees-list">
                {filteredAttendees.map((email, index) => (
                    <div
                        key={index}
                        className="attendee-card"
                        style={{ backgroundColor: checkedInAttendees.includes(email) ? '#e6ffe6' : 'white' }}
                    >
                        <div className="attendee-avatar">
                            <span className="avatar-text">
                                {getNameFromEmail(email).charAt(0).toUpperCase()}
                            </span>
                        </div>
                        <div className="name-email-div">
                            <span><b>{getNameFromEmail(email)}</b></span>
                            <span>{email}</span>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const scannerCallback = async (userEmail, event_id, err) => {
        // Force React to give us the latest state
        const currentAttendees = await new Promise(resolve => {
            setCheckedInAttendees(prev => {
                resolve(prev);
                return prev;
            });
        });

        console.log("Current checkedInAttendees:", currentAttendees)
        console.log("Attempting to check in:", userEmail)

        if (err == "Invalid Ticket") {
            setError(err);
            setMessage('Invalid Ticket');
            return;
        } else if (err == "User Not Found") {
            setError(err);
            setMessage(`${userEmail} is not registered/approved`);
            return;
        } else if (currentAttendees.includes(userEmail)) {
            console.log("User already checked in - this should show on second scan")
            setError("Already Checked In");
            setMessage(`${userEmail} is already checked in`);
            return;
        }

        try {
            const newCheckedInAttendees = [...info.checkedInAttendees];
            newCheckedInAttendees[registeredIndex] = [userEmail, ...currentAttendees];

            await axios.put(`${process.env.REACT_APP_SERVER_URL}events/${event_id}/`, {
                checkedInAttendees: newCheckedInAttendees
            }, {
                headers: {
                    'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
                }
            });

            console.log("DB update successful")
            setCheckedInAttendees([userEmail, ...currentAttendees]);
            setError(false);
            setMessage(`${userEmail} has been checked in.`);
        } catch (error) {
            console.error("Error in update:", error)
            setError("Update Failed");
            setMessage("Failed to check in user. Please try again.");
        }
    }

    return (
        <>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#002244',
                    },
                }}
            >
                <Button
                    type="primary"
                    icon={<CameraOutlined />}
                    onClick={handleOpenModal}
                    size="large"
                    style={{
                        flex: .67,
                        borderRadius: '4px',
                        boxShadow: 'none',
                    }}
                >
                    Scan Ticket
                </Button>
            </ConfigProvider>

            <ConfigProvider
                theme={{
                    components: {
                        Modal: {
                            titleFontSize: 20,
                        },
                    },
                }}
            >
                <Modal
                    title="Scan Ticket"
                    open={isOpen}
                    onCancel={handleCloseModal}
                    width={800}
                    style={{ top: 20 }}
                    bodyStyle={{
                        maxHeight: 'calc(100vh - 225px)',
                        overflow: 'auto',
                    }}
                    footer={[
                        <Button key="close" onClick={handleCloseModal}>
                            Close
                        </Button>
                    ]}
                >
                    <div className="scanner-container">
                        <div className="scanner-view">
                            

                            <Scanner
                                event_id={(info.event_id).toString()}
                                onScanComplete={scannerCallback}
                                attendees={attendees}
                                checkedInAttendees={checkedInAttendees}
                                fromQR={fromQR}
                                qrUserId={qrUserId}
                                className="scanner"
                                // modalOpen getting passed over is not consistently checking if its open
                                modalOpen={isOpen}
                            />

                            {message && (
                                <div className="scanner-response-field" style={{
                                    border: `2px solid ${!error ? 'green' : error === 'Already Checked In' ? '#FFB800' : 'red'}`,
                                    borderRadius: '8px',
                                }}>
                                    <p>{message}</p>
                                    <CloseIcon className="close" onClick={() => {
                                        setMessage('')
                                        setError('')
                                    }} />
                                </div>
                            )}

                        </div>

                        <div className="registered-attendees">
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '8px'
                            }}>
                                <h3 style={{ margin: 0 }}>Attendees</h3>
                                <Segmented
                                    options={['All', 'Checked In', 'Pending']}
                                    value={attendeeFilter}
                                    onChange={setAttendeeFilter}
                                    style={{ marginLeft: 'auto' }}
                                />
                            </div>
                            {renderAttendeesList()}
                        </div>
                    </div>
                </Modal>
            </ConfigProvider>
        </>
    );
};

export default TicketScanner;