import EventCard from "./EventCard";
import React, { useState } from 'react';
import { useAppData } from './context/DataContext';
import { Link } from "react-router-dom";
import Navbar from './Navbar';
import Footer from './Footer';
import OrganizerNavbar from "./OrganizerNavbar.js";
import { ReactComponent as Avatar } from "./IconUmmahfy.svg";
import { Segmented } from 'antd';
import { ReactComponent as NoEvents } from './NoEvents.svg';

const Profile = () => {

  const {
    informationWithPastEvents,
    loggedInUser,
    loggedInEmail,
    userInfo
  } = useAppData();

  const [user, setUser] = useState(loggedInUser.data);
  const [email, setEmail] = useState(loggedInEmail.data);
  const [userDetails, setUserDetails] = useState(userInfo ? userInfo : null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('Upcoming');  // Add this for the toggle

  function createCard(info) {
    return (
      <Link key={info.event_id} to={`/event/${info.event_id}`} className="card-link">
        <EventCard
          imgurl={info.img}
          event={info.name}
          masjid={info.organization}

          // date={info.dateIndex == -1 ? info.dates[0] : info.dates[info.dateIndex]}
          altdate={info.timeArray[0]}

          description={info.description}
          age={info.age_range}
          frequency={info.frequency}
          price={info.price}
          gender={info.gender}
          registered={info.registered}

          time={info.time}
          tags={info.tags}
        />
      </Link>
    );
  }

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const searchIds = (userDetails && userDetails.signed_up_events) 
    ? userDetails.signed_up_events.filter(event => !isNaN(event)) 
    : [];

  function filterEventsByIds(events, searchIds) {
    return events.filter(event => searchIds.includes(event.event_id.toString()));
  }

  const filteredEventsByIds = filterEventsByIds(informationWithPastEvents, searchIds);
  // Separate past and upcoming events
  const pastEvents = filteredEventsByIds.filter(event => {
    const eventDate = event.start_datetime ? event.start_datetime : event.dates[0];
    return new Date(eventDate) < currentDate || event.past === true;
});

  const upcomingEvents = filteredEventsByIds.filter(event => {
    const eventStartDate = new Date(event.start_datetime ? event.start_datetime : event.dates[0]);
    return eventStartDate >= currentDate && event.past !== true;
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userDetails) {
    return <div>Error loading user details.</div>;
  }

  console.log(upcomingEvents)

  return (
    
    <>
    {console.log(informationWithPastEvents, "info with past events here")}
      <div className="wrapper">
        {loggedInUser.data === "Enterprise User" ? (
          <OrganizerNavbar />
        ) : (
          <Navbar />
        )}
        <div className="profile-container">
          <div className="profile-header">
            <Avatar className="profile-image"/>
            <div className="profile-details">
              <h2>{userDetails?.username || 'User'}</h2>
              <p>{userDetails?.signed_up_events?.length || 0} Events signed up</p>
            </div>
          </div>

          <div className="org-title-toggle">
            <h2 className="registered-events-title">Registered Events</h2>
            <Segmented
              size="large"
              className="org-toggle"
              options={['Upcoming', 'Past']}
              value={value}
              onChange={setValue}
            />
          </div>

          {value === 'Upcoming' ? (
            upcomingEvents.length > 0 ? (
              upcomingEvents.map(createCard)
            ) : (
              <div className="no-events-message">
                <NoEvents className="prof-no-events" />
              </div>
            )
          ) : (
            pastEvents.length > 0 ? (
              pastEvents.map(createCard)
            ) : (
              <div className="no-events-message">
                <NoEvents className="prof-no-events" />
              </div>
            )
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;