import Navbar from "./Navbar";
import Footer from "./Footer";
import OrganizerNavbar from "./OrganizerNavbar.js";
import React, { useState, useEffect } from 'react';
import { useAppData } from './context/DataContext';
import { getEventLoc } from './information.service.js'
import { DatePicker, TimePicker, Tooltip, Modal, ConfigProvider, Input, Segmented, Switch, Button, Dropdown } from 'antd';
import LocationSearchInput from "./LocationSearchInput.js";
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment';
import { Link } from "react-router-dom";
import TagSelector from "./TagSelector.js";
import AgeSelector from "./AgeSelector.js";
import GenderSelector from "./GenderSelector.js";
import FileUpload from "./FileUpload.js";
import { DownOutlined } from '@ant-design/icons';
import DaySelector from "./DaySelector.js";
import FormBuilder from './FormBuilder';
import { PlusOutlined } from '@ant-design/icons';  // Make sure to import this


const secrets = require('./secrets.js');
const api = secrets.server_url;

function NewCreateEvent() {
    const {
        loggedInUser,
        loggedInEmail,
        selectedOrganization,
        selectedOrganizationInfo
    } = useAppData();

    // const organizerOptions = ["Masjid", "Community"];
    // const ageOptions = ["N/A", "Youth", "HS/College", "Adult"];
    // const genderOptions = ["N/A", "Male", "Female"];
    // const recurringOptions = ["N/A", "Daily", "Weekly", "Monthly"];

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 890 ? false : true);
    const [text, setText] = useState('');
    const [value, setValue] = useState('In-Person');

    const [loading, setLoading] = useState(false);

    const [confirmationModal, setConfirmationModal] = useState(false);

    const { RangePicker } = DatePicker;
    // const { Option } = Select;

    const [frequency, setFrequency] = useState("Once"); // once, weekly, monthly, custom
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [customDatesList, setCustomDatesList] = useState([]);
    const [selectedDays, setSelectedDays] = useState("");
    const [recurrence, setRecurrence] = useState(1);

    const [isTransitioning2, setIsTransitioning2] = useState(false);
    const [rValue, setRValue] = useState('Once');
    const [previewDates, setPreviewDates] = useState([]);

    const [isFormBuilderVisible, setIsFormBuilderVisible] = useState(false);

    const handleFormSave = (formData) => {
        console.log('Form Data:', formData);
        // Handle the saved form data here
        setEventData(prevData => ({
            ...prevData,
            customFormQuestions: formData
        }));
    };



    const showConfirmationModal = () => {
        console.log(1)
        setConfirmationModal(true);
    };

    const handleConfirmationCancel = () => {
        console.log(1)
        setConfirmationModal(false);
        window.location.reload();
    };


    const { TextArea } = Input;

    // useEffect(() => {
    //     if (rValue === 'Recurring') {
    //         if (startDate && endDate && selectedDays.length !== 0 && recurrence) {
    //             calculateAllDates().then(calculatedDates => {
    //                 console.log("dates preview");
    //                 console.log(calculatedDates);
    //                 setPreviewDates(calculatedDates);
    //             }).catch(error => {
    //                 console.error("Error calculating dates:", error);
    //                 // Handle the error appropriately
    //             });
    //         } else {
    //             setPreviewDates([]);
    //         }
    //     }
    // }, [startDate, endDate, selectedDays, recurrence, rValue]);

    // Update the useEffect to be more specific about when it should run
    useEffect(() => {
        console.log(1)
        let isMounted = true;

        const updatePreviewDates = async () => {
            // Only calculate if we're in recurring mode and have all necessary values
            if (rValue !== 'Recurring' || !startDate || !endDate || !selectedDays || selectedDays.length === 0) {
                return;
            }

            try {
                const calculatedDates = await calculateAllDates();
                if (isMounted && calculatedDates) {
                    setPreviewDates(calculatedDates);
                }
            } catch (error) {
                console.error("Error calculating dates:", error);
            }
        };

        updatePreviewDates();

        // Cleanup function
        return () => {
            isMounted = false;
        };
    }, [startDate, endDate, selectedDays, recurrence, rValue]); // Remove frequency from dependencies if not directly used




    // const handleFrequencyChange = (value) => {

    //     if (value === "Recurring") {
    //         console.log("ksdjksdjbf")
    //         setFrequency('Weekly')
    //     }
    //     else if (value === "week") {
    //         setFrequency('Weekly')
    //     }
    //     else if (value === "month") {
    //         setFrequency('Monthly')
    //     } else {
    //         setFrequency(value)
    //     }
    // };

    const handleFrequencyChange = (value) => {
        console.log(1)
        let newFrequency = value;
        if (value === "Recurring") {
            console.log('handlefreq recur')
            newFrequency = 'Weekly';
        } else if (value === "week") {
            console.log('handlerval week')
            newFrequency = 'Weekly';
        } else if (value === "month") {
            console.log('handlefreq month')
            newFrequency = 'Monthly';
        }
        setFrequency(newFrequency);
    };

    const handleDateChange = (dates) => {
        console.log(1)
        console.log("handledatechange")
        if (!dates) {
            setStartDate('');
            setEndDate('');
            return;
        }
        const [start, end] = dates;
        setStartDate(start ? start.toISOString().split('T')[0] : '');
        setEndDate(end ? end.toISOString().split('T')[0] : '');
    };


    const handleTimeChange = (times) => {
        console.log(1)
        console.log("handletimechange")
        if (times) {
            const [startTime, endTime] = times;
            setEventDataItem("start_time", startTime ? startTime.format('HH:mm') + ':00.000' : '')
            setEventDataItem("end_time", endTime ? endTime.format('HH:mm') + ':00.000' : '')

        } else {
            setEventDataItem("start_time", '')
            setEventDataItem("end_time", '')
        }
    };

    const handleCustomDatesListChange = (value) => {
        console.log(1)
        if (value) {
            const dateStrings = value.map(date => date.toISOString().split('T')[0]);
            setCustomDatesList(dateStrings);
        } else {
            setCustomDatesList([]);
        }
    };

    const handleSelectedDaysChange = (value) => {
        console.log(1)
        const newDays = Array.isArray(value) ? value : [value];
        // Only update if the days have actually changed
        if (JSON.stringify(newDays) !== JSON.stringify(selectedDays)) {
            setSelectedDays(newDays);
        }
    };

    const handleRecurrenceChange = (value) => {
        console.log(1)
        setRecurrence(value)
    };

    // const [date, setDate] = useState("");
    // const [startTime, setStartTime] = useState("");
    // const [endTime, setEndTime] = useState("");

    const [eventData, setEventData] = useState({
        name: '',
        organization: loggedInUser.data === "Enterprise User" ? selectedOrganizationInfo.name : loggedInUser.data,
        creator: loggedInEmail.data || "",
        frequency: '',
        dates: [],
        start_time: '',
        end_time: '',
        description: '',
        img: '',
        price: '',
        location: '',
        latitude: '',
        longitude: '',
        age_range: '',
        gender: '',
        isApproved: false,
        capacity: '',
        isPrivate: false,
        hideCapacity: false,
        requireApproval: false,
        approvedAttendees: [],
        tags: [],
        virtual_link: '',
        customFormQuestions: [],
        hideLocation: false,
    });



    // useEffect(() => {
    //     setEventData(prevData => ({
    //         ...prevData,
    //         start_datetime: startTime ? `${date}T${startTime}` : date,
    //         end_datetime: endTime ? `${date}T${endTime}` : date
    //     }));
    // }, [date, startTime, endTime]);



    // const onDateChange = (date, dateString) => {
    //     setDate(dateString);
    // };

    // const onStartTimeChange = (time, timeString) => {
    //     if (time) {
    //         const formattedTimeString = time.format('HH:mm');
    //         setStartTime(`${formattedTimeString}:00.000`);
    //     }
    //     else {
    //         setStartTime('')
    //     }

    // };

    // const onEndTimeChange = (time, timeString) => {
    //     if (time) {
    //         const formattedTimeString = time.format('HH:mm');
    //         setEndTime(`${formattedTimeString}:00.000`);
    //     }
    //     else {
    //         setEndTime('')
    //     }
    // };

    const uploadImage = async (imageFile) => {
        try {
            // Create FormData object
            const formData = new FormData();
            formData.append('image', imageFile);

            // Make the request
            axios.defaults.maxBodyLength = 50 * 1024 * 1024; // 50MB
            const response = await axios.post(`${api}uploadImage`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true, // Important for CORS with credentials
            });

            // Response will contain imageUrl if successful
            console.log('Upload successful! Image URL:', response.data.imageUrl);
            return response.data.imageUrl;

        } catch (error) {
            console.error('Error uploading image:', error.response?.data?.message || error.message);
            throw error;
        }
    };

    //call on submit                                //start date, end date, frequency
    const calculateAllDates = async () => {
        console.log(1)
        // console.log(frequency)
        switch (frequency) {
            case 'Once':
                if (startDate === endDate) {
                    return [startDate];
                } else {
                    return await getDatesBetween(startDate, endDate);
                }
            case 'Weekly':
                return await getRecurringDates(startDate, endDate, recurrence, frequency, selectedDays);
            case 'Monthly':
                return await getRecurringDates(startDate, endDate, recurrence, frequency, selectedDays[0]);
            case 'Custom':
                return customDatesList;
            default:
                return [];
        }
    };

    async function getDatesBetween(startDateStr, endDateStr) {
        console.log(1)
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);
        const dateArray = [];

        // Ensure end date is greater than or equal to start date
        if (startDate > endDate) {
            return dateArray; // Return an empty array if dates are in wrong order
        }

        // Create a date pointer starting from the start date
        let currentDate = new Date(startDate);

        // Loop through each day, adding it to the array
        while (currentDate <= endDate) {
            dateArray.push(currentDate.toISOString().split('T')[0]); // Add the current date as a string (YYYY-MM-DD)
            currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
        }

        return dateArray;
    }


    function getRecurringDates(startDate, endDate, recurrence, freq, daysOfWeek) {
        console.log(1)
        // console.log(startDate)
        // console.log(endDate)
        // console.log(recurrence)
        // console.log(freq)
        // console.log(daysOfWeek)
        // Convert input dates to UTC midnight
        const start = new Date(Date.UTC(new Date(startDate).getUTCFullYear(), new Date(startDate).getUTCMonth(), new Date(startDate).getUTCDate()));
        const end = new Date(Date.UTC(new Date(endDate).getUTCFullYear(), new Date(endDate).getUTCMonth(), new Date(endDate).getUTCDate()));
        const dateArray = [];

        if (start > end) return dateArray;

        const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const dayIndices = freq === 'Weekly'
            ? daysOfWeek.map(day => dayNames.indexOf(day)).sort((a, b) => a - b)
            : [dayNames.indexOf(daysOfWeek)];

        function addDays(date, days) {
            const result = new Date(date);
            result.setUTCDate(result.getUTCDate() + days);
            return result;
        }

        function getWeekOfMonth(date) {
            const firstDay = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1));
            return Math.ceil((date.getUTCDate() + firstDay.getUTCDay() - 1) / 7);
        }

        function getNthDayOfMonth(year, month, n, dayIndex) {
            const firstDay = new Date(Date.UTC(year, month, 1));
            const dayOffset = (dayIndex - firstDay.getUTCDay() + 7) % 7;
            const targetDate = new Date(Date.UTC(year, month, 1 + dayOffset + (n - 1) * 7));
            return targetDate.getUTCMonth() === month ? targetDate : null;
        }

        function getUTCDateString(date) {
            return `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')}`;
        }

        if (freq === 'Weekly') {
            let current = new Date(start);

            // Find the first occurrence on or after the start date
            while (!dayIndices.includes(current.getUTCDay())) {
                current = addDays(current, 1);
            }

            while (current <= end) {
                if (current >= start) {
                    dateArray.push(getUTCDateString(current));
                }

                // Find the next occurrence
                let daysToAdd = 1;
                while (!dayIndices.includes((current.getUTCDay() + daysToAdd) % 7)) {
                    daysToAdd++;
                }

                current = addDays(current, daysToAdd);

                // Apply recurrence if we've gone through all days in the pattern
                if (dayIndices.indexOf(current.getUTCDay()) === 0) {
                    current = addDays(current, 7 * (recurrence - 1));
                }
            }
        } else if (freq === 'Monthly') {
            const startWeek = getWeekOfMonth(start);
            const dayIndex = dayIndices[0];
            let current = new Date(Date.UTC(start.getUTCFullYear(), start.getUTCMonth(), 1));

            while (current <= end) {
                const targetDate = getNthDayOfMonth(current.getUTCFullYear(), current.getUTCMonth(), startWeek, dayIndex);
                if (targetDate && targetDate >= start && targetDate <= end) {
                    dateArray.push(getUTCDateString(targetDate));
                }
                current.setUTCMonth(current.getUTCMonth() + recurrence);
            }
        }

        return dateArray;
    }

    // function assertEquals(actual, expected, message) {
    //     const passed = JSON.stringify(actual) === JSON.stringify(expected);
    //     console.log(passed ? `PASSED: ${message}` : `FAILED: ${message}`);
    //     if (!passed) {
    //         console.log('Expected:', expected);
    //         console.log('Actual:', actual);
    //     }
    // }

    // // Test cases
    // function runTests() {
    //     // Test 1: Monthly recurrence - 2nd Tuesday for 6 months
    //     assertEquals(
    //         getRecurringDates('2024-07-09', '2024-12-31', 1, 'Monthly', 'Tuesday'),
    //         ['2024-07-09', '2024-08-13', '2024-09-10', '2024-10-08', '2024-11-12', '2024-12-10'],
    //         'Monthly recurrence - 2nd Tuesday for 6 months'
    //     );

    //     // Test 2: Monthly recurrence - 3rd Friday for 4 months
    //     assertEquals(
    //         getRecurringDates('2024-03-15', '2024-06-30', 1, 'Monthly', 'Friday'),
    //         ['2024-03-15', '2024-04-19', '2024-05-17', '2024-06-21'],
    //         'Monthly recurrence - 3rd Friday for 4 months'
    //     );

    //     // Test 3: Weekly recurrence - Every Monday and Wednesday for 3 weeks
    //     assertEquals(
    //         getRecurringDates('2024-01-01', '2024-01-21', 1, 'Weekly', ['Monday', 'Wednesday']),
    //         ['2024-01-01', '2024-01-03', '2024-01-08', '2024-01-10', '2024-01-15', '2024-01-17'],
    //         'Weekly recurrence - Every Monday and Wednesday for 3 weeks'
    //     );

    //     // Test 4: Weekly recurrence - Every other Thursday for 2 months
    //     assertEquals(
    //         getRecurringDates('2024-02-01', '2024-03-31', 2, 'Weekly', ['Thursday']),
    //         ['2024-02-01', '2024-02-15', '2024-02-29', '2024-03-14', '2024-03-28'],
    //         'Weekly recurrence - Every other Thursday for 2 months'
    //     );

    //     // Test 5: Monthly recurrence - 5th Wednesday (should skip months without 5th Wednesday)
    //     assertEquals(
    //         getRecurringDates('2024-01-31', '2024-12-31', 1, 'Monthly', 'Wednesday'),
    //         ['2024-01-31', '2024-05-29', '2024-07-31', '2024-10-30'],
    //         'Monthly recurrence - 5th Wednesday (skipping months without)'
    //     );

    //     // Test 6: Monthly recurrence across year boundary
    //     assertEquals(
    //         getRecurringDates('2024-11-15', '2025-02-28', 1, 'Monthly', 'Friday'),
    //         ['2024-11-15', '2024-12-20', '2025-01-17', '2025-02-21'],
    //         'Monthly recurrence across year boundary'
    //     );

    //     // Test 7: Weekly recurrence with start date not on recurrence day
    //     assertEquals(
    //         getRecurringDates('2024-03-20', '2024-04-10', 1, 'Weekly', ['Monday']),
    //         ['2024-03-25', '2024-04-01', '2024-04-08'],
    //         'Weekly recurrence with start date not on recurrence day'
    //     );

    //     // Test 8: Monthly recurrence with end date before next occurrence
    //     assertEquals(
    //         getRecurringDates('2024-01-31', '2024-02-27', 1, 'Monthly', 'Wednesday'),
    //         ['2024-01-31'],
    //         'Monthly recurrence with end date before next occurrence'
    //     );

    //     // Test 9: Weekly recurrence with multiple days including start date
    //     assertEquals(
    //         getRecurringDates('2024-06-05', '2024-06-20', 1, 'Weekly', ['Monday', 'Wednesday', 'Friday']),
    //         ['2024-06-05', '2024-06-07', '2024-06-10', '2024-06-12', '2024-06-14', '2024-06-17', '2024-06-19'],
    //         'Weekly recurrence with multiple days including start date'
    //     );

    //     // Test 10: Invalid input handling
    //     assertEquals(
    //         getRecurringDates('2024-01-01', '2023-12-31', 1, 'Monthly', 'Monday'),
    //         [],
    //         'Invalid input: End date before start date'
    //     );

    //     console.log('All tests completed.');
    // }

    // // Run the tests
    // runTests();


    const handleChange = (e) => {
        console.log(1)
        const { name, value } = e.target;
        // console.log(name)
        // console.log(value)
        setEventData(prevData => ({
            ...prevData,
            [name]: value === 'N/A' ? '' : value
        }));
    };

    const setEventDataItem = (name, value) => {
        console.log(1)
        // console.log(name)
        // console.log(value)
        setEventData(prevData => ({
            ...prevData,
            [name]: value
        }));

    };


    const handlePlaceSelected = async (place) => {
        console.log(1)
        //console.log(place)
        await setEventData(prevData => ({
            ...prevData,
            location: place.formatted_address
        }))

        if (place) {
            //console.log("calling geteventloc")
            const coords = await getEventLoc(place.formatted_address)
            //console.log("coords:")
            //console.log(coords)
            // //console.log(eventData)
            // //console.log(eventData.location)
            // //console.log(coords)
            setEventData(prevData => ({
                ...prevData,
                latitude: coords.latitude
            }));
            setEventData(prevData => ({
                ...prevData,
                longitude: coords.longitude
            }));
        }

    };

    const handleFileSelect = async (file) => {
        console.log(1)
        setEventData(prevData => ({
            ...prevData,
            img: file
        }));
    };

    // const fetchSessionEmail = async () => {
    //     const response = await axios.get(`${api}getLoggedInEmail/`, {
    //       withCredentials: true, // Include cookies with requests
    //     });
    //     return response.data;
    // };

    async function createPaymentLink(event_id) {
        console.log(1)
        try {
            if (!selectedOrganizationInfo?.stripeAccountId) return;

            const response = await axios.post(`${api}stripe/createStripeEvent`, {
                eventName: eventData.name,
                eventDescription: eventData.description,
                ticketPrice: Number(eventData.price) * 100,
                currency: 'usd',
                organizationId: selectedOrganizationInfo?.stripeAccountId,
                eventId: event_id
            });

            if (response.data && response.data.event) {
                console.log('Paid event created successfully:', response.data.event);
            }
        } catch (error) {
            console.error('Error creating paid event:', error);
        }
    }


    const handleCreateEvent = async (e) => {
        e.preventDefault();
        // console.log(0)

        // console.log(eventData.name)
        // console.log(startDate)
        // console.log(endDate)
        // console.log(eventData.location)
        // console.log(eventData.start_time)
        // console.log(eventData.end_time)
        // console.log(eventData.virtual_link);

        if (!eventData.name || !((startDate && endDate) || customDatesList.length > 0) || !eventData.start_time || !eventData.end_time || (!eventData.location && !eventData.virtual_link)) {
            alert("Please fill out all required fields.");
            console.error('Please fill out all required fields.');
            return;
        }

        setLoading(true);
        try {


            // Only try to upload if an image was provided
            if (eventData.img) {
                eventData.img = await uploadImage(eventData.img);
            } else {
                eventData.img = 'https://img.ummahfy.com/NoFlyer.jpeg' // default placeholder
            }


            const calculatedDates = await calculateAllDates();

            // Create a new object with the updated data
            const updatedEventData = {
                ...eventData,
                dates: calculatedDates,
                frequency: frequency,
            };

            console.log(updatedEventData)

            if (frequency !== "Once") {
                updatedEventData.registered = Array.from({ length: updatedEventData.dates.length }, () => []);
                updatedEventData.approvedAttendees = Array.from({ length: updatedEventData.dates.length }, () => []);
                updatedEventData.checkedInAttendees = Array.from({ length: updatedEventData.dates.length }, () => []);
            } else {
                updatedEventData.registered = [[]]
                updatedEventData.approvedAttendees = [[]]
                updatedEventData.checkedInAttendees = [[]]
            }

            if (loggedInUser.data === "Enterprise User" && selectedOrganizationInfo.isApproved) {
                updatedEventData.isApproved = true
            }

            // Update the state
            setEventData(updatedEventData);

            const response = await axios.post(`${api}events/`, updatedEventData, {
                headers: {
                    'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
                }
            })

            if (loggedInUser.data === "Enterprise User") {
                console.log(2)
                // add event id to organization's events
                console.log(response)
                const updatedEventsArr = selectedOrganizationInfo.events
                updatedEventsArr.push(response.data.data.event_id)

                const response2 = await axios.put(`${api}organizations/`, {
                    events: updatedEventsArr
                }, {
                    params: {
                        name: selectedOrganization
                    },
                    headers: {
                        'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
                    }
                })

                console.log("pushed eventid to org events arr")

                //create payment link
                if (!isNaN(eventData.price) && eventData.price > 0) {
                    console.log("price is a number")
                    if (!selectedOrganizationInfo?.stripeAccountId || selectedOrganizationInfo?.stripeAccountStatus === "pending") {
                        console.log("stripe account not set up or verification still pending")
                    }
                    else {

                        await createPaymentLink(response.data.data.event_id)
                    }
                }
            }

            //send confirmation email
            if (loggedInEmail.data) {
                console.log("send conf email")
                const payload = {
                    email: loggedInEmail.data,
                    eventName: eventData.name,
                    type: "Create Event"
                };

                try {
                    const response = await axios.post(`${api}sendEmail`, payload, {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY,
                        }
                    });
                    console.log('Response:', response.data);
                } catch (error) {
                    console.error('Error:', error.response ? error.response.data : error.message);
                }
            }

            setLoading(false);
            setConfirmationModal(true)

            console.log("after")

        } catch (error) {
            console.error('Error creating event:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        console.log(1)
        const handleResize = () => {
            if (window.innerWidth <= 480) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);



    const onChange = (checked) => {
        console.log(1)
        console.log(`switch to ${checked}`);
    };

    // const handleKeyDown = (e) => {
    //     console.log(1)
    //     if (e.key === 'Enter') {
    //         e.preventDefault();
    //     }
    // };

    // const handleFileSelect = async (file) => {
    //     setEventData(prevData => ({
    //         ...prevData,
    //         img: file
    //     }));
    // };

    const [isTransitioning, setIsTransitioning] = useState(false);

    const handleValueChange = (newValue) => {
        console.log(1)
        setIsTransitioning(true);
        setValue(newValue);
        setTimeout(() => setIsTransitioning(false), 400); // Match this with your CSS transition duration
    };




    const handleApprovalChange = (checked) => {
        console.log(1)
        console.log(checked)
        setEventDataItem("requireApproval", checked)
    };

    const handleHideLocationChange = (checked) => {
        console.log(1)
        console.log(checked)

        setEventDataItem("hideLocation", checked)
    };

    const handleHideCapacityChange = (checked) => {
        console.log(1)
        console.log(checked)

        setEventDataItem("hideCapacity", checked)
    };

    const handleIsPrivateChange = (checked) => {
        console.log(1)
        console.log(checked)

        setEventDataItem("isPrivate", checked)
    };

    const [recurringType, setRecurringType] = useState('daily');

    // const handleRecurringTypeChange = (value) => {
    //     console.log(1)
    //     setRecurringType(value);
    // };

    const [selectedNumber, setSelectedNumber] = useState('1');

    const handleMenuClick = ({ key }) => {
        console.log(1)
        setSelectedNumber(key);
        handleRecurrenceChange(key)
    };

    const numberItems = [
        { key: '1', label: '1' },
        { key: '2', label: '2' },
        { key: '3', label: '3' },
    ];

    const menuProps = {
        items: numberItems,
        onClick: handleMenuClick,
    };

    const [selectedDuration, setSelectedDuration] = useState('week');

    // const handleDurationMenuClick = ({ key }) => {
    //     setSelectedDuration(key);
    //     handleFrequencyChange(key)
    //     { console.log(selectedDuration, "this is the selected duration") }
    // };

    const handleDurationMenuClick = ({ key }) => {
        console.log(1)
        setSelectedDuration(key);
        if ((key === 'week' && frequency !== 'Weekly') ||
            (key === 'month' && frequency !== 'Monthly')) {
            handleFrequencyChange(key);
        }
    };

    const durationItems = [
        { key: 'week', label: 'week' },
        { key: 'month', label: 'month' },

    ];

    const durationMenuProps = {
        items: durationItems,
        onClick: handleDurationMenuClick,
    };

    const handleRValueChange = (value) => {
        console.log(1)
        setIsTransitioning2(true);
        setRValue(value);

        // Reset states based on the new value
        if (value === 'Recurring') {
            console.log('handlerval recur')
            setCustomDatesList([]);
            setStartDate('');
            setEndDate('');
            // setFrequency('Weekly'); // Set initial frequency
        } else if (value === 'Custom') {
            console.log('handlervalcustom')
            setStartDate('');
            setEndDate('');
            setSelectedDays([]);
            setRecurrence(1);
        } else if (value === 'Once') {
            setCustomDatesList([]);
            setSelectedDays([]);
            setRecurrence(1);
        }

        // Clear common fields
        setEventDataItem('start_time', '');
        setEventDataItem('end_time', '');
        setPreviewDates([]);



        setTimeout(() => setIsTransitioning2(false), 300);
    };

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            ['link'],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link',
    ];


    const [isModalVisible, setIsModalVisible] = useState(false);

    const [isUserStripeModal, setIsUserStripeModal] = useState(false);

    const handleInputClick = () => {
        console.log(1)
        if (loggedInUser.data === "Enterprise User" &&
            !(selectedOrganizationInfo?.stripeAccountId &&
                selectedOrganizationInfo?.stripeAccountStatus === "active")) {
            setIsModalVisible(true);
        }

        if (loggedInUser.data !== "Enterprise User") {
            setIsUserStripeModal(true);
        }

    };

    console.log("this is the user log in data", loggedInUser.data)
    console.log(startDate)
    console.log(endDate)
    console.log(selectedDays)
    console.log(recurrence)
    console.log(rValue)


    const handleModalCancel = () => {
        console.log(1)
        setIsModalVisible(false);
    };

    const handleIsUserStripeCancel = () => {
        console.log(1)
        setIsUserStripeModal(false);
    };



    const handleDescChange = (content) => {
        console.log(1)
        setText(content);
        setEventDataItem("description", content);
        // If you really need to remove the outer <p> tags:
        // setEventDataItem("description", content.replace(/^<p>|<\/p>$/g, ''));
    };

    const dateOptions = [
        { value: 'Once', label: 'Once' },
        { value: 'Recurring', label: 'Recurring', disabled: false },
        { value: 'Custom', label: 'Custom' }
    ];

    const handleSegmentedChange = (value) => {
        console.log(1)
        if (!dateOptions.find(opt => opt.value === value)?.disabled) {
            handleRValueChange(value);
            handleFrequencyChange(value);
        }
    };

    const settings = [
        {
            title: "Require Approval",
            subtext: "Require approval for event registration",
        },
        {
            title: "Private Event",
            subtext: "Restrict event visibility to invited attendees only",
        },
        {
            title: "Hide Capacity",
            subtext: "Hide the event capacity from attendees",
        },
        {
            title: "Hide Location",
            subtext: "Hide the event location from attendees until registration is approved",
        },
    ];

    return <>

        <div class="wrapper event-shell-wrapper">
            {loggedInUser.data === "Enterprise User" ? (
                <OrganizerNavbar />
            ) : (
                <Navbar />
            )}

            <div class="event-shell-container new-es-c">

                <div class="flyer1">
                    <FileUpload onFileSelect={handleFileSelect} />
                </div>



                <div class="event-shell-info0" >
                    {/* <input class="event-input" placeholder="Event Name" name="name" value={eventData.name} onChange={handleChange} /> */}
                    <TextArea placeholder="Event Name" autoSize className="event-input" name='name' onChange={handleChange} />
                </div>



                <div class="event-shell-info1" >

                    <div className="event-shell-date1">
                        <div className="time-title-toggle">
                            <label className="org-time-title">
                                {
                                    {
                                        'Once': ' Event Time',
                                        'Recurring': 'Date Range',
                                        'Custom': 'Custom Dates'
                                    }[rValue] || 'Select Event Time'
                                }
                            </label>

                            <Segmented
                                size="small"
                                className="org-toggle"
                                options={dateOptions}
                                value={rValue}
                                onChange={handleSegmentedChange}  // Use the pre-defined handler
                            />
                        </div>

                        <div className={`event-options ${isTransitioning2 ? 'transitioning' : ''}`}>
                            <div key={`once-${rValue}`} className={`event-option ${rValue === 'Once' ? 'active' : ''}`}>
                                {rValue === 'Once' && (
                                    <>

                                        <RangePicker
                                            className="date-range-picker"
                                            inputReadOnly={isMobile}
                                            onChange={handleDateChange}
                                            format="MM/DD/YYYY"
                                            onOpenChange={(open) => {
                                                if (open) {
                                                    document.body.classList.add('lock-scroll');
                                                } else {
                                                    document.body.classList.remove('lock-scroll');
                                                }
                                            }}
                                            placement="bottomLeft"
                                        />
                                        <TimePicker.RangePicker
                                            inputReadOnly={isMobile}
                                            use12Hours
                                            format="h:mm a"
                                            className="time-range-picker"
                                            needConfirm={false}
                                            onChange={handleTimeChange}
                                        // onOpenChange={(open) => {
                                        //     if (open) {
                                        //         document.body.classList.add('lock-scroll');
                                        //     } else {
                                        //         document.body.classList.remove('lock-scroll');
                                        //     }
                                        // }}
                                        />

                                    </>
                                )}
                            </div>

                            <div key={`recurring-${rValue}`} className={`event-option ${rValue === 'Recurring' ? 'active' : ''}`}>
                                {rValue === 'Recurring' && (
                                    <>
                                        <RangePicker
                                            inputReadOnly={isMobile}

                                            className="date-range-picker"
                                            onChange={handleDateChange}
                                            format="MM/DD/YYYY"  // or "MMM D, YYYY" for Jan 1, 2024
                                        />

                                        <div className="duration-days">
                                            <div className="duration">
                                                <label> Repeats Every </label>
                                                <div className="number-duration">
                                                    <Dropdown
                                                        menu={menuProps}
                                                        placement="bottom"
                                                        arrow
                                                    >
                                                        <Button className="recurring-number" >
                                                            {selectedNumber}
                                                            <DownOutlined />
                                                        </Button>
                                                    </Dropdown>

                                                    <Dropdown
                                                        menu={durationMenuProps}
                                                        placement="bottom"
                                                        arrow
                                                    >
                                                        <Button className="recurring-duration" >
                                                            <span className="duration" >{selectedDuration}</span>

                                                            <DownOutlined />
                                                        </Button>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <div className="days-section">
                                                <label> On </label>
                                                <div className="days-row">
                                                    <DaySelector
                                                        onChange={handleSelectedDaysChange}
                                                        isMonthly={selectedDuration === 'month'}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <label> Select Time</label>
                                        <TimePicker.RangePicker
                                            inputReadOnly={isMobile}
                                            use12Hours
                                            format="h:mm a"
                                            className="time-range-picker"
                                            style={{ marginTop: '8px' }}
                                            needConfirm={false}
                                            onChange={handleTimeChange}
                                        />

                                        <label className="event-times-label"> Event Times</label>
                                        {previewDates.length > 0 ? (
                                            <div className="preview-dates">
                                                {previewDates.map((date, index) => (
                                                    <div key={index} className="custom-date-item">
                                                        {moment(date).format('ddd, MMM D, YYYY')}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="recurring-text-div">
                                                <p className="recurring-subtext"> Recurring event times will display here </p>
                                            </div>

                                        )}
                                    </>
                                )}
                            </div>

                            <div key={`custom-${rValue}`} className={`event-option ${rValue === 'Custom' ? 'active' : ''}`}>
                                {rValue === 'Custom' && (
                                    <div className="custom-option">
                                        <DatePicker
                                            multiple
                                            onChange={handleCustomDatesListChange}
                                            className="date-range-picker"
                                            maxTagCount="responsive"
                                            size="medium"
                                        />
                                        <TimePicker.RangePicker
                                            inputReadOnly={isMobile}
                                            use12Hours
                                            format="h:mm a"
                                            className="time-range-picker"
                                            needConfirm={false}
                                            onChange={handleTimeChange}
                                        />

                                        <label class="event-times-label"> Event Times</label>
                                        {customDatesList.length > 0 ? (
                                            <div className="preview-dates">
                                                {customDatesList.map((date, index) => (
                                                    <div key={index} className="custom-date-item">
                                                        {moment(date).format('ddd, MMM D, YYYY')}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <p class="recurring-subtext"> Recurrring event times will display here </p>
                                        )
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="event-shell-info1">
                    <div className="event-shell-location1">
                        <div className="loc-label-toggle">
                            <label>{value === 'In-Person' ? 'Enter a Location' : 'Link for Virtual Event'}</label>
                            <Segmented
                                size="small"
                                className="org-toggle"
                                options={['In-Person', 'Virtual']}
                                value={value}
                                name='virtual_link'
                                onChange={handleValueChange}
                            />
                        </div>

                        <div className={`location-content ${isTransitioning ? 'transitioning' : ''}`}>
                            <div className={`in-person-content ${value === 'In-Person' ? 'active' : ''}`}>
                                <LocationSearchInput
                                    onPlaceSelected={handlePlaceSelected}

                                />

                            </div>

                            <div className={`virtual-content ${value === 'Virtual' ? 'active' : ''}`}>
                                <input
                                    type="text"
                                    placeholder="Enter a link for Zoom, Google Meet, etc"
                                    className="virtual-link-input"
                                    name="virtual_link"
                                    onChange={handleChange}
                                />

                            </div>

                        </div>
                        {/* <label className="approval-toggle">
                            <Switch size="small" onChange={handleHideLocationChange} />
                            <p className="require-approval">Hide Location</p>
                        </label> */}
                    </div>
                </div>

                <div class="event-shell-info1">
                    <div class="event-shell-description1">

                        <div className="price-input1">
                            <div className="price-tooltip-label">
                                <label htmlFor="price">Price </label>
                                <div className="info-tooltip">
                                    <Tooltip title="Ummahfy handles all payments and ticketing - access your Stripe dashboard from settings to view and manage payments.">
                                        <span>?</span>
                                    </Tooltip>
                                </div>
                            </div>

                            {/* temp fix for require approval not allowing paid events  */}
                            {eventData.requireApproval ? (
                                <Tooltip title="Events that require approval cannot be paid">
                                    <div>
                                        <input
                                            type="number"
                                            maxLength="8"
                                            id="cost"
                                            name="price"
                                            className="ce-input2"
                                            onChange={handleChange}
                                            placeholder='Free'
                                            autoComplete="off"
                                            onClick={handleInputClick}
                                            readOnly={true}
                                            disabled
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <input
                                    type="number"
                                    maxLength="8"
                                    id="cost"
                                    name="price"
                                    className="ce-input2"
                                    onChange={handleChange}
                                    placeholder='Free'
                                    autoComplete="off"
                                    onClick={handleInputClick}
                                    readOnly={!(loggedInUser.data === "Enterprise User" &&
                                        selectedOrganizationInfo?.stripeAccountId &&
                                        selectedOrganizationInfo?.stripeAccountStatus === "active")}
                                />
                            )}

                            <ConfigProvider
                                theme={{
                                    components: {
                                        Modal: {
                                            titleFontSize: 20,

                                        },
                                    },
                                }}
                            >
                                <Modal
                                    className="ce-modal"
                                    title="Stripe Integration"
                                    visible={isModalVisible}
                                    onCancel={handleModalCancel}
                                    footer={null}
                                    closable={true}
                                    maskClosable={true}
                                    centered
                                >
                                    <p class="stripe-modal-text">Set up your Stripe account with Ummahfy to edit the price for your event.</p>
                                    <Link to="/organizerSettings">
                                        <button className="join stripe-btn">Connect to Stripe</button>
                                    </Link>
                                </Modal>
                            </ConfigProvider>

                            <ConfigProvider
                                theme={{
                                    components: {
                                        Modal: {
                                            titleFontSize: 20,


                                        },
                                    },
                                }}
                            >
                                <Modal
                                    className="attendees-modal"
                                    title="Want to Create Paid Events?"
                                    visible={isUserStripeModal}
                                    onCancel={handleIsUserStripeCancel}
                                    footer={null}
                                    closable={true}
                                    maskClosable={true}
                                    centered
                                >
                                    <p class="stripe-modal-text">Create an organization to create paid events, manage payments and tickets, and more.</p>
                                    {/* <Link to="/">
                                        <button className="join stripe-btn"> Create an Org Account</button>
                                    </Link> */}
                                </Modal>
                            </ConfigProvider>
                        </div>


                        <div class="capacity-input1">
                            <label htmlFor="capacity" class="ce-label">Capacity</label>
                            <input
                                type="number"
                                maxLength="8"
                                id="capacity"
                                name="capacity"
                                className="ce-input2"
                                onChange={handleChange}

                                placeholder='Unlimited'
                                autoComplete="off"

                            />
                        </div>

                        <div className="description-input1">
                            <label htmlFor="description" class="ce-label"> Description </label>
                            {/* <Editor value={text} onTextChange={(e) => setEventDataItem("description", e.htmlValue.substring(3, e.htmlValue.length - 4))} style={{ height: '200px', borderColor: '#e6e6e6', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px', }} /> */}
                            <ReactQuill
                                theme="snow"
                                className="my-custom-quill"
                                modules={modules}
                                formats={formats}
                                value={text}
                                onChange={handleDescChange}
                            // onTextChange={(e) => setEventDataItem("description", e.htmlValue.substring(3, e.htmlValue.length - 4))}
                            />
                        </div>

                        {/* <div className="form-builder">
                            <label htmlFor="form-builder" class="ce-label">Form Builder</label>
                            <p className="form-builder-description">
                                Customize your event registration by adding additional questions for attendees to answer when they register.
                            </p>
                            <Button onClick={() => setIsFormBuilderVisible(true)}>Add Form</Button>

                            <FormBuilder
                                isVisible={isFormBuilderVisible}
                                onCancel={() => setIsFormBuilderVisible(false)}
                                onSave={handleFormSave}
                            />

                        </div> */}

                        {/* <label className="registration-approval">
                            <Switch size="small" onChange={handleApprovalChange} />
                            <p className="require-approval">Require Registration Approval</p>
                        </label> */}

                        {/* <label className="registration-approval">
                            <Switch size="small"/>
                            <p className="require-approval">Hide Capacity</p>
                        </label>

                        <label className="registration-approval">
                            <Switch size="small"/>
                            <p className="require-approval">Private</p>
                        </label> */}


                    </div>
                </div>

                <div className="event-shell-info1">
                    <label htmlFor="form-builder" class="ce-label">Form Builder</label>
                    {/* <p className="form-builder-description">
                    Create a better registration experience by adding custom questions.
                     Collect the information you need to make your event a success.                 
                     </p> */}
                    <Button

                        icon={<PlusOutlined />}
                        onClick={() => setIsFormBuilderVisible(true)}
                        style={{
                            borderRadius: '4px',
                            width: '100%',

                        }}
                    >
                        Add Form
                    </Button>
                    <FormBuilder
                        isVisible={isFormBuilderVisible}
                        onCancel={() => setIsFormBuilderVisible(false)}
                        onSave={handleFormSave}
                    />

                </div>

                <div class="event-shell-info1">
                    <p>Settings & Preferences</p>

                    <div className="ce-toggle-container">
                        {/* {settings.map((setting, index) => (
                            <label key={index} className="ce-toggle-div">
                                <div className="ce-title-toggle">
                                    <p>{setting.title}</p>
                                    <Switch
                                        onChange={(checked) => {
                                            if (setting.title === 'Require Approval') {
                                                console.log("reqapp")

                                                handleApprovalChange(checked)
                                            } else if (setting.title === 'Private Event') {
                                                console.log("priv")

                                                handleIsPrivateChange(checked)
                                            } else if (setting.title === 'Hide Capacity') {
                                                console.log("hidecap")

                                                handleHideCapacityChange(checked)
                                            } else if (setting.title === 'Hide Location') {
                                                console.log("hideloc")
                                                handleHideLocationChange(checked)
                                            }
                                        }} />
                                </div>
                                <p className="ce-toggle-subtext">{setting.subtext}</p>
                            </label>
                        ))} */}

                        {/* temp fix for require approval not allowing paid events */}
                        {settings.map((setting, index) => (
                            <label key={index} className="ce-toggle-div">
                                <div className="ce-title-toggle">
                                    <p>{setting.title}</p>
                                    {setting.title === 'Require Approval' ? (
                                        <Tooltip title="Paid events can not require approval">
                                            <div>
                                                <Switch
                                                    disabled={!isNaN(eventData.price) && eventData.price > 0}
                                                    onChange={(checked) => handleApprovalChange(checked)}
                                                />
                                            </div>
                                        </Tooltip>
                                    ) : (
                                        <Switch
                                            onChange={(checked) => {
                                                if (setting.title === 'Private Event') {
                                                    handleIsPrivateChange(checked)
                                                } else if (setting.title === 'Hide Capacity') {
                                                    handleHideCapacityChange(checked)
                                                } else if (setting.title === 'Hide Location') {
                                                    handleHideLocationChange(checked)
                                                }
                                            }}
                                        />
                                    )}
                                </div>
                                <p className="ce-toggle-subtext">{setting.subtext}</p>
                            </label>
                        ))}
                    </div>

                </div>

                <div class="under-flyer-2">
                    <AgeSelector onChange={(value) => setEventDataItem("age_range", value)} />
                </div>
                <div class="under-flyer-3">
                    <GenderSelector onChange={(value) => setEventDataItem("gender", value)} />
                </div>
                <div class="under-flyer">
                    <TagSelector onChange={(value) => setEventDataItem("tags", value)} />
                </div>


                <button class="join submit-event" onClick={handleCreateEvent}> Submit Event </button>

                <ConfigProvider
                    theme={{
                        components: {
                            Modal: {
                                titleFontSize: 20,

                            },
                        },
                    }}
                >
                    <Modal
                        className="ce-modal"
                        title="Event Request Sent"
                        visible={confirmationModal}
                        onCancel={handleConfirmationCancel}
                        footer={null}
                        closable={true}
                        maskClosable={true}

                        centered
                    >
                        <p class="stripe-modal-text">Your event is pending approval, you'll be notified by email when we have confirmed it.</p>

                    </Modal>
                </ConfigProvider>

            </div>
        </div>

        <Footer> </Footer>
    </>
}

export default NewCreateEvent;