import React, { useState, useEffect } from 'react';
import { useAppData } from './context/DataContext';
import { getDateIndex } from './information.service.js'
import { useLocation } from 'react-router-dom';
import { ReactComponent as Location } from './Location.svg';
import { useParams } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import { ClockCircleOutlined, CalendarOutlined, LaptopOutlined, } from '@ant-design/icons';
import { TicketIcon } from 'lucide-react';
import OrganizerNavbar from "./OrganizerNavbar.js";
import axios from "axios";
import ShareButton from "./ShareButton.js";
import RegisterModal from "./RegisterModal.js";
import RequestRegistrationModal from "./RequestRegistrationModal.js";
import EditRSVP from "./EditRSVP.js";
import Ummahfy from './Ummahfy.png';
import { Helmet } from "react-helmet";
import Signin from "./Signin.js";
import OrganizerSignIn from "./OrganizerSignIn.js";
import OrgEditEvent from "./OrgEditEvent.js";
import AttendeesModal from './AttendeesModal';
import CustomFormModal from './CustomFormModal.js';
import TicketScanner from './TicketScanner.js';
import { Modal, ConfigProvider, Tooltip, Form, Button, } from 'antd';
import { Users, MapPin } from "lucide-react"

const EventShell = () => {

  const theme = {
    components: {
      Button: {
        defaultHoverBg: '#f7f7f7',        // Background color on hover
        defaultHoverBorderColor: '#f7f7f7', // Border color on hover
        defaultHoverColor: '#002244',       // Text color on hover
        defaultShadow: 'none'
      },
    },
  };

  const {
    information,
    informationWithPastEvents,
    loggedInUser,
    loggedInEmail,
    userInfo,
    selectedOrganization,
    users
  } = useAppData();

  const [scannerFromQR, setScannerFromQR] = useState(false);
  const [qrUserId, setQrUserId] = useState(null);
  const [isTickerScannerOpen, setIsTicketScannerOpen] = useState(false);

  const handleOpenTicketScanner = () => {
    setIsTicketScannerOpen(true);
  };

  const handleCloseTicketScanner = () => {
    setIsTicketScannerOpen(false);
  };

  useEffect(() => {
    console.log("event data:")
    console.log(information)
  }, [information]);

  const secrets = require("./secrets.js");
  const api = secrets.server_url;

  let { id } = useParams();
  id = Number(id)

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get('session_id');
  const organizationId = queryParams.get('orgId');
  ////console.log(id)
  const info = informationWithPastEvents.find((item) => item.event_id === id);
  console.log(informationWithPastEvents)
  console.log(info)

  console.log(info)
  if (info.registered.length === 0) {
    info.registered.push([])
  }

  // const dateIndex = findFirstFutureDate(info.dates)
  const dateIndex = info.dateIndex

  let startDate = info.frequency === "Once" ? info.dates[0] : info.dates[dateIndex]
  startDate = startDate.split('T')[0]

  let endDate = info.frequency === "Once" ? info.dates[info.dates.length - 1] : info.dates[dateIndex]
  endDate = endDate.split('T')[0]

  const registeredIndex = info.frequency === "Once" ? 0 : dateIndex
  const startTime = info.start_time ? info.start_time : info.start_datetime.split('T')[1]
  const endTime = info.end_time ? info.end_time : info.end_datetime.split('T')[1]

  //console.log(info)

  console.log(loggedInEmail)
  console.log(dateIndex)

  console.log(info.registered[0])

  console.log(info.registered)
  console.log(registeredIndex)
  const [isAdmin, setIsAdmin] = useState(userInfo ? userInfo.isAdmin : false);
  const [registerModal, setRegisterModal] = useState(false);
  const [requestRegistrationModal, setRequestRegistrationModal] = useState(false);
  const [isRegistered, setIsRegistered] = useState(info.registered[registeredIndex].includes(loggedInEmail.data));
  const [isApproved, setIsApproved] = useState(info.requireApproval && isRegistered && info.approvedAttendees[registeredIndex]?.includes(loggedInEmail.data));
  const [editRsvp, setEditRsvp] = useState(false);
  const [adminEditEvent, setAdminEditEvent] = useState(false);
  const [orgEditEvent, setOrgEditEvent] = useState(false);

  const [user, setUser] = useState(loggedInUser.data);
  const [email, setEmail] = useState(loggedInEmail.data);


  const [buttonSignin, setButtonSignin] = useState(false);
  const [organizerSignin, setOrganizerSignin] = useState(false);


  const [isPaid, setIsPaid] = useState(false);

  const [hostOrg, setHostOrg] = useState(null);


  // const verifyPayment = async () => {
  //   try {
  //     const { data } = await axios.get(`${api}stripe/retrieve-session/${sessionId}`, {
  //       params: {
  //         organizationId: organizationId // Add the org ID
  //       }
  //     }
  //     );

  //     console.log(data)
  //     if (data.session.payment_status === 'paid') {
  //       ///add to registered and send conf email
  //       setIsPaid(true)
  //       console.log("paid for event")

  //       await handleRegister(id)

  //       // window.location.href = window.location.href.split('?')[0];

  //     }
  //   } catch (error) {
  //     console.error('Verification error:', error);
  //   }
  // };

  // if (!isRegistered && sessionId) {
  //   verifyPayment();
  // }




  async function getHostOrg() {
    try {
      const response = await axios.get(`${api}organizations/`, {
        headers: {
          'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        },
        params: {
          name: info.organization
        }
      });
      if (response && response.data && response.data.data && response.data.data[0]) {
        return response.data.data[0];
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  useEffect(() => {
    async function fetchHostOrg() {
      try {
        const orgData = await getHostOrg();
        setHostOrg(orgData);
      } catch (error) {
        console.error("Error fetching host organization:", error);
      }
    }

    fetchHostOrg();
  }, [info.organization]); // Add info.organization as a dependency

  // for ticket qr code scanning
  useEffect(() => {
    // Get URL parameters
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId');

    if (userId) {
      setQrUserId(userId);

      // If user is logged in and is an organizer, open scanner
      if (user && (isAdmin || selectedOrganization === info.organization)) {
        setScannerFromQR(true);
        handleOpenTicketScanner();

        // Clean up URL parameters
        const newUrl = window.location.pathname;
        window.history.replaceState({}, '', newUrl);
      }
      // If user is not logged in, redirect to organizer sign in
      else if (!user) {
        setOrganizerSignin(true);
        // URL parameters will persist through redirect and sign in
      }
      // If user is logged in but not an organizer, show message and clean up URL
      else {
        Modal.info({
          title: 'Access Denied',
          content: 'Only event organizers can scan tickets.',
        });
        const newUrl = window.location.pathname;
        window.history.replaceState({}, '', newUrl);
      }
    }
  }, [user, isAdmin, selectedOrganization, location.search]);


  const signInCallback = () => {
    // After successful sign in, check if there's a pending QR scan
    if (qrUserId && (isAdmin || selectedOrganization === info.organization)) {
      setScannerFromQR(true);
      setIsTicketModalVisible(true);
    }
  };

  const formattedDescription = info.description.replace(/\\n/g, "\n");


  window.scrollTo(0, 0);

  function GoogleMap({ address }) {
    const apiKey = secrets.google_maps_api_key;


    return (
      <>
        {address ? (
          <a className="es-map" href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`} target="_blank" rel="noopener noreferrer">
            <iframe
              title="Google Map"
              className="event-shell-map"
              frameBorder="0" // Remove the default border
              allowFullScreen
              loading="eager"
              src={`https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(address)}&zoom=15&key=${apiKey}`}
            ></iframe>
          </a>
        ) : (
          <div class="map-error-mask">

          </div>
        )}
      </>
    );
  }


  async function getUserDetails(email) {
    try {
      const response = await axios.get(`${api}users/${email}/`, {
        headers: {
          'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        }
      });
      //console.log("User details:", response.data.data[0].email);
      return response.data.data[0];
    } catch (error) {
      console.error(error);
      throw error; // Handle or rethrow error
    }
  }

  const updateUserDetails = async (email, updates) => {

    // //console.log(updates);
    try {
      const response = await axios.put(`${api}users/${email}/`, updates, {
        headers: {
          'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        }
      });
      return response.data.data;
    } catch (error) {
      console.error("Error updating user details:", error);
      throw error;
    }
  };

  const uploadTicketImg = async (base64Image, fileName) => {
    try {
      const response = await axios.post(`${api}uploadBase64Image/`, {
        base64Image,
        fileName,
      });
      console.log('Base64 image uploaded successfully:', response.data.imageUrl);
      return response.data.imageUrl;
    } catch (error) {
      console.error('Error uploading base64 image:', error);
    }
  };

  async function handleRegister(id) {
    // const user = await fetchSessionData();
    //console.log("calling handle register")
    //console.log(user)

    if (info.tickets_link) {
      window.open(info.tickets_link, '_blank', 'noopener,noreferrer');
      return
    }

    console.log("inhandleregister")

    if (!user) {
      // if no user is logged in
      //console.log("ayo u gotta log in bruh");
      // //console.log(user)
      alert("you need to sign in")
      return;
    }

    if (!id) {
      return
    }

    var currentEventData = await axios.get(`${api}events/${id}`, {
      headers: {
        'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
      }
    })

    currentEventData = currentEventData.data.data

    const currentDateIndex = await getDateIndex(currentEventData.dates)
    const currStartDate = info.frequency === "Once" ? info.dates[0] : info.dates[dateIndex]
    const currEndDate = info.frequency === "Once" ? info.dates[info.dates.length - 1] : info.dates[dateIndex]
    const currRegisteredIndex = info.frequency === "Once" ? 0 : dateIndex


    //console.log("user is logged in", user);
    // var info = await informationWithPastEvents.find((item) => item.event_id === id);

    if (!currentEventData) {
      return;
    }

    if (currentEventData.registered[currRegisteredIndex].includes(email)) {
      //console.log("User is already registered for this event.");
      return;
    }

    currentEventData.registered[currRegisteredIndex].push(email);


    //console.log("Updated event info with new registration:", info);

    try {
      const response = await axios.put(`${api}events/${info.event_id}`, currentEventData, {
        withCredentials: true,
        headers: {
          'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        }
      });
      console.log(response)

      // Fetch the updated user details
      console.log("getting user details")

      const userDetails = await getUserDetails(email);

      console.log(userDetails.signed_up_events)
      userDetails.signed_up_events.push(info.event_id)
      // Update the user's details in the database, with req.body which is signed_up_events
      //console.log("NOWW", userDetails.signed_up_events);
      const updatedUserDetails = await updateUserDetails(email, { signed_up_events: userDetails.signed_up_events });
      console.log(updatedUserDetails)
      //console.log("YOOO", updatedUserDetails); //! not showing up here

      //generate ticket
      let ticketURL

      const ticketResponse = await axios.post(`${api}generateTicket`, {
        eventId: info.event_id,
        userId: userInfo._id
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        }
      });

      console.log("ticket:")
      console.log(ticketResponse.data.data)

      ticketURL = await uploadTicketImg(ticketResponse.data.data, info.event_id + "-" + loggedInEmail)

      //save ticketURL to db
      let newTickets = userInfo.tickets || {}
      newTickets[info.event_id] = ticketURL
      console.log('newTickets:', newTickets)
      const response2 = await axios.put(`${api}users/${loggedInEmail.data}`, {
        tickets: newTickets
      }, {
        withCredentials: true,
        headers: {
          'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        }
      });




      //send confirmation email
      if (loggedInEmail.data) {
        console.log("sending conf email")
        const payload = {
          email: loggedInEmail.data,
          eventName: info.name,
          eventURL: window.location.href,
          ticket: ticketURL,                        //ticketURL
          type: "Register for Event",
          eventLocation: info.location,
          dateTime:
            new Date(startDate + 'T00:00:00').toLocaleDateString('en-US')
            + " @ " +
            new Date(`2000-01-01T${startTime}`).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: '2-digit',
              hour12: true
            })
        };

        console.log(payload)

        try {
          const response = await axios.post(`${api}sendEmail`, payload, {
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
            }
          });
          console.log('Response:', response.data);
        } catch (error) {
          console.error('Error:', error.response ? error.response.data : error.message);
        }
      }


    } catch (error) {
      console.error("Error updating event or user details:", error);
    }
  }

  async function handleRegistrationRequest(id) {
    if (!user) {
      alert("you need to sign in")
      return;
    }

    if (!id) {
      return
    }

    var currentEventData = await axios.get(`${api}events/${id}`, {
      headers: {
        'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
      }
    })

    currentEventData = currentEventData.data.data

    const currRegisteredIndex = info.frequency === "Once" ? 0 : dateIndex

    if (!currentEventData) {
      return;
    }

    if (currentEventData.registered[currRegisteredIndex].includes(email)) {
      return;
    }

    currentEventData.registered[currRegisteredIndex].push(email);

    try {
      const response = await axios.put(`${api}events/${info.event_id}`, currentEventData, {
        withCredentials: true,
        headers: {
          'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        }
      });

      //update user info
      const userDetails = await getUserDetails(email);
      userDetails.signed_up_events.push(info.event_id)
      const updatedUserDetails = await updateUserDetails(email, { signed_up_events: userDetails.signed_up_events });

    } catch (error) {
      console.error("Error updating event or user details:", error);
    }
  }

  async function handleRegistrationApproval(email) {
    //get user
    const userIndex = users.findIndex(user => user.email === email);

    if (userIndex == -1) {
      console.error("user not found")
    }

    const userToApprove = users[userIndex]

    //generate ticket
    let ticketURL

    const ticketResponse = await axios.post(`${api}generateTicket`, {
      eventId: info.event_id,
      userId: userToApprove._id
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
      }
    });

    console.log("ticket:")
    console.log(ticketResponse.data.data)

    ticketURL = await uploadTicketImg(ticketResponse.data.data, info.event_id + "-" + email)

    //save ticketURL to db
    let newTickets = userToApprove.tickets || {}
    newTickets[info.event_id] = ticketURL
    console.log('newTickets:', newTickets)
    const response2 = await axios.put(`${api}users/${email}`, {
      tickets: newTickets
    }, {
      withCredentials: true,
      headers: {
        'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
      }
    });


    //send confirmation email

    console.log("sending conf email")
    const payload = {
      email: email,
      eventName: info.name,
      eventURL: window.location.href,
      ticket: ticketURL,                        //ticketURL
      type: "Registration Request Approved",
      eventLocation: info.location,
      dateTime:
        new Date(startDate + 'T00:00:00').toLocaleDateString('en-US')
        + " @ " +
        new Date(`2000-01-01T${startTime}`).toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        })
    };

    console.log(payload)

    try {
      const response = await axios.post(`${api}sendEmail`, payload, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        }
      });
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }

  }

  async function handleUnRegister(id) {
    //console.log("calling handle UNregister");
    //console.log(user)
    // const user = await fetchSessionData();
    // //console.log("UUSISSRE", user);


    if (!user) {
      // if no user is logged in
      //console.log("ayo u gotta log in bruh");
      //console.log(user)
      return;
    }

    if (!id) {
      return
    }

    var currentEventData = await axios.get(`${api}events/${id}`, {
      headers: {
        'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
      }
    })
    currentEventData = currentEventData.data.data

    let currentDateIndex = await getDateIndex(currentEventData.dates)
    // const currStartDate = info.frequency === "Once" ? info.dates[0] : info.dates[dateIndex]
    // const currEndDate = info.frequency === "Once" ? info.dates[info.dates.length - 1] : info.dates[dateIndex]
    const currRegisteredIndex = info.frequency === "Once" ? 0 : dateIndex


    if (!currentEventData.registered[currRegisteredIndex].includes(email)) {
      //console.log("User is not registered for this event.");
      return;
    }

    // info.registered.push(user);
    let index = currentEventData.registered[currRegisteredIndex].indexOf(email);
    if (index !== -1) {
      currentEventData.registered[currRegisteredIndex].splice(index, 1);
    }

    try {
      const response = await axios.put(`${api}events/${info.event_id}`, currentEventData, {
        withCredentials: true,
        headers: {
          'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        }
      });

      // Fetch the updated user details

      const userDetails = await getUserDetails(email);

      //console.log(userDetails)
      // userDetails.signed_up_events.push(info.name);
      let index = userDetails.signed_up_events.indexOf(info.event_id.toString());
      //console.log(index)
      if (index !== -1) {
        await userDetails.signed_up_events.splice(index, 1);
      }
      // Update the user's details in the database, with req.body which is signed_up_events
      //console.log("NOWW", userDetails.signed_up_events);
      const updatedUserDetails = await updateUserDetails(email, { signed_up_events: userDetails.signed_up_events });
      //console.log("YOOO", updatedUserDetails); //! not showing up here
    } catch (error) {
      console.error("Error updating event or user details:", error);
    }
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  function formatEventTime() {
    // Combine date and time strings

    console.log(startDate)
    console.log(startTime)
    console.log(endDate)
    console.log(endTime)

    const start = new Date(`${startDate}T${startTime}`);
    const end = new Date(`${endDate}T${endTime}`);

    console.log(start)
    console.log(end)

    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      console.error("Invalid date or time format");
      return { formattedDate: "Invalid date or time format", formattedTime: "" };
    }

    // Format the dates (e.g., "Mon, Jun 17" and "Thu, Jul 25")
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
      weekday: 'long',
      month: 'short',
      day: 'numeric'
    });

    const startDateFormatted = dateFormatter.format(start);
    const endDateFormatted = dateFormatter.format(end);

    // Format the times (e.g., "8:30am" and "1:20pm")
    const timeFormatter = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });

    // Format start time and remove ':00' if present
    let startTimeFormatted = timeFormatter.format(start).replace(/ /g, '').replace('AM', ' am').replace('PM', 'pm');
    if (startTimeFormatted.endsWith(':00am') || startTimeFormatted.endsWith(':00pm')) {
      startTimeFormatted = startTimeFormatted.replace(':00', '');
    }

    // Format end time and remove ':00' if present
    let endTimeFormatted = timeFormatter.format(end).replace(/ /g, '').replace('AM', ' am').replace('PM', 'pm');
    if (endTimeFormatted.endsWith(':00am') || endTimeFormatted.endsWith(':00pm')) {
      endTimeFormatted = endTimeFormatted.replace(':00', '');
    }

    // Determine if the event spans multiple days
    let formattedDate;
    if (startDateFormatted === endDateFormatted) {
      // Same day
      formattedDate = `${startDateFormatted}`;
    } else {
      // Different days
      formattedDate = `${startDateFormatted} - ${endDateFormatted}`;
    }

    let formattedTime;
    if (startTimeFormatted === endTimeFormatted) {
      formattedTime = "Starts at " + startTimeFormatted;
    } else {
      formattedTime = `${startTimeFormatted} - ${endTimeFormatted}`;
    }

    return { formattedDate, formattedTime };
  }

  const { formattedDate, formattedTime } = formatEventTime();
  const formatPrice = (price) => {
    console.log("check if price is 0 or free", price)

    // Check if price is 0 or "Free" and return "Free" directly
    if (price === "0" || price === "Free") {
      return "Free";
    }

    // Convert price to string if it's a number
    let priceStr = price.toString();

    // Add '$' if it's not already present
    if (!priceStr.startsWith("$")) {
      priceStr = `$${priceStr}`;
    }

    return priceStr;
  };

  console.log("adsjbnfkwejbfkwbnfowbnfkwefbuwdlknsdlkncowlebnfowibenfkwenfowe")
  console.log(hostOrg)

  const getAttendeeDisplay = (info, dateIndex) => {
    // Get number of attendees based on approval requirement and date

    const dateIndex2 = registeredIndex

    let attendeeCount;

    if (info.requireApproval) {
      // If approval required, count approved attendees
      if (isAdmin || selectedOrganization === info.organization || info.creator === loggedInEmail.data) {
        attendeeCount = info.registered?.[dateIndex2]?.length ?? 0;
      } else {
        attendeeCount = info.approvedAttendees[registeredIndex].length
      }
    }
    else {
      // Count registrations for specific date
      attendeeCount = info.registered?.[dateIndex2]?.length ?? 0;
    }

    // Display count with or without capacity
    if (info.capacity > 0) {
      return `${attendeeCount} / ${info.capacity}`;
    }

    return `${attendeeCount}`;
  };


  //checking if info tickets link has stripe
  const isStripe = info.stripeProductId && info.stripePriceId

  const handleStripeRedirect = async (eventId, organizationId) => {
    try {
      const { data } = await axios.post(`${api}stripe/create-checkout-session`, {
        eventId: info.event_id,
        organizationId: hostOrg.stripeAccountId,
        userEmail: loggedInEmail.data
      });

      // Redirect to Stripe Checkout
      window.location.href = data.sessionUrl;

    } catch (error) {
      console.error('Error creating checkout session:',
        error.response?.data?.error || error.message
      );
      // Handle error appropriately
    }
  };


  // Function to dynamically generate Open Graph meta tags
  const getMetaTags = () => {
    return (
      <Helmet>
        <title>{info.name}</title>
        <meta property="og:title" content={info.name} />
        <meta property="og:description" content={info.description} />
        <meta property="og:url" content={`https://www.ummahfy.com/event/${info.event_id}`} />
        <meta property="og:image" content={info.img} />
      </Helmet>
    );
  };


  // Function to check if event is full
  const isEventFull = () => {
    if (!info.capacity || info.capacity === 0) return false;

    const registeredCount = info.registered[registeredIndex]

    return registeredCount >= info.capacity;
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [customForm, setCustomForm] = useState(false);

  const handleCustomFormModal = () => {
    console.log("is bball working", customForm)
    setCustomForm(true);
  }


  const handleInputClick = () => {
    setIsModalVisible(true);
  }

  const handleModalCancel = () => {
    console.log("MODAL CANCEL ")
    setIsModalVisible(false);
    setCustomForm(false);
    setScannerFromQR(false);
    setQrUserId(null);
  };

  const [form] = Form.useForm();  // Add this at the component level


  const [isTicketModalVisible, setIsTicketModalVisible] = useState(false);

  // Add these handler functions
  const showTicketModal = () => {
    setIsTicketModalVisible(true);
  };

  const handleTicketModalCancel = () => {
    setIsTicketModalVisible(false);
  };

  const [approvedAttendees, setApprovedAttendees] = useState(info.approvedAttendees[registeredIndex])

  const onAttendeeApprovalChange = (approved) => {
    console.log(approved)
    setApprovedAttendees(approved)

    let newApprovedAttendees = info.approvedAttendees || []
    newApprovedAttendees[registeredIndex] = approved

    const response = axios.put(`${api}events/${info.event_id}`, {
      approvedAttendees: newApprovedAttendees
    }, {
      withCredentials: true,
      headers: {
        'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
      }
    });
  }

  return (
    <>

      {getMetaTags()}

      {info.customFormQuestions && info.customFormQuestions.length > 0 && (
        <CustomFormModal
          isOpen={customForm}
          onClose={handleModalCancel}
          onSubmit={isStripe ? handleStripeRedirect : (info.requireApproval ? (values) => handleRegistrationRequest(id) : (values) => handleRegister(id))}
          form={form}
          info={info}
          isRegistered={isRegistered}
          loggedInEmail={loggedInEmail}
          isStripe={isStripe}
        />
      )}

      <div class="wrapper event-shell-wrapper">
        {console.log(loggedInUser.data, "checking on event shell")}
        {loggedInUser.data === "Enterprise User" ? (

          <OrganizerNavbar />
        ) : (
          <Navbar />
        )}

        <div class="event-shell-container">
          <div class="flyer">
            <img
              className="imgStyle"
              src={info.img}
              loading="lazy"
              alt={`${info.name} event flyer`}
            />
          </div>



          <div class="title-register">


            <p class="event-shell-name"> {info.name} </p>

            <div className="avatar-org">
              {/* 
            <Link to="/organizerHome" style={{textDecoration: "none", display:"flex"}}> */}
              {hostOrg && hostOrg.profilePicture ? (
                <img
                  className="avatar-icon"
                  src={hostOrg.profilePicture}
                  loading="lazy"
                  alt={`${info.organization} logo`}
                />
              ) : (
                <svg className="avatar-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zM6.023 15.416C7.491 17.606 9.695 19 12.16 19c2.464 0 4.669-1.393 6.136-3.584A8.968 8.968 0 0 0 12.16 13a8.968 8.968 0 0 0-6.137 2.416zM12 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  </g>
                </svg>
              )}
              <p className="event-shell-org">{info.organization}</p>
              {/* </Link> */}
            </div>

            <>
              {user ? (
                <>
                  {isRegistered || isPaid ? (
                    <>
                      <div className="rsvp-ticket-btns">
                        {info.requireApproval ? (
                          // Handle approval required cases
                          <>
                            {isApproved ? (
                              <button
                                className="already-registered-btn"
                                onClick={() => {
                                  setEditRsvp(true);
                                }}
                              >
                                Edit RSVP
                              </button>
                            ) : (
                              <button
                                className="pending-approval-btn"
                                disabled
                              >
                                Registration Pending Approval
                              </button>
                            )}
                          </>
                        ) : (
                          // No approval required - show regular edit RSVP button
                          <button
                            className="already-registered-btn"
                            onClick={() => {
                              setEditRsvp(true);
                            }}
                          >
                            Edit RSVP
                          </button>
                        )}

                        <ConfigProvider
                          theme={{
                            token: {
                              colorPrimary: '#cca33c',
                            },
                          }}
                        >
                          {isRegistered && (!info.requireApproval || isApproved) && (
                            <Button
                              type="primary"
                              className="ticket-btn"
                              onClick={showTicketModal}
                              icon={<TicketIcon />}
                              style={{
                                boxShadow: 'none'
                              }}
                            >
                            </Button>
                          )}
                        </ConfigProvider>

                        {/* Rest of the ticket modal code remains the same */}
                        <ConfigProvider theme={{
                          components: {
                            Modal: {
                              titleFontSize: 20,
                            },
                          },
                        }}>
                          <Modal
                            title="Your Ticket"
                            open={isTicketModalVisible}
                            onCancel={handleTicketModalCancel}
                            footer={null}
                            width={400}
                          >

                            <div className="ticket-content" style={{ width: '60%', height: '210px', backgroundColor: 'grey', borderRadius: '8px', margin: '30px auto' }}>
                              <img style={{ borderRadius: '8px' }} src={userInfo.tickets ? userInfo.tickets[info.event_id] : null}></img>
                            </div>

                          </Modal>
                        </ConfigProvider>
                      </div>

                      <EditRSVP
                        id={id}
                        handleUnRegister={handleUnRegister}
                        trigger={editRsvp}
                        setTrigger={setEditRsvp}
                        isPaid={isPaid}
                        info={info}
                        isRegistered={isRegistered}
                        isStripe={isStripe}
                      />
                    </>
                  ) : (
                    <>
                      {loggedInUser.data === "Enterprise User" ? (
                        <div style={{ display: 'flex', gap: '8px' }}>
                          <button
                            className="passed-button"
                            disabled
                            title="Enterprise users cannot register for this event"
                          >
                            Org Accounts Cannot Register
                          </button>
                        </div>
                      ) : (
                        <>
                          {(info.requireApproval && info.capacity > 0 && info.approvedAttendees[registeredIndex].length >= info.capacity) ||
                            (!info.requireApproval && info.capacity > 0 && info.registered[registeredIndex].length >= info.capacity) ? (
                            <button
                              className="passed-button"
                              disabled
                              title="Event has reached capacity"
                            >
                              {/* {isStripe ? "Sold Out" : "Event Full"} */}
                              Sold Out
                            </button>
                          ) : (
                            <>
                              {info.customFormQuestions && info.customFormQuestions.length > 0 ? (
                                <button
                                  className="register-btn"
                                  onClick={handleCustomFormModal}
                                >
                                  {info.requireApproval ? 'Request Registration' : (isStripe ? 'Purchase Tickets' : 'Register for this Event')}
                                </button>
                              ) : (
                                <>
                                  {isStripe ? (
                                    <button
                                      className="register-btn"
                                      onClick={handleStripeRedirect}
                                    >
                                      Purchase Tickets
                                    </button>
                                  ) : (
                                    <button
                                      className="register-btn"
                                      onClick={() => {
                                        info.requireApproval ? handleRegistrationRequest(id) : handleRegister(id);
                                        info.requireApproval ? setRequestRegistrationModal(true) : setRegisterModal(true);
                                      }}
                                    >
                                      {info.requireApproval ? 'Request Registration' : 'Register for this Event'}
                                    </button>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {!isStripe && !(info.customFormQuestions && info.customFormQuestions.length > 0) && (
                        <RegisterModal
                          trigger={registerModal}
                          setTrigger={setRegisterModal}
                          info={info}
                        />
                      )}
                      {!isStripe && !(info.customFormQuestions && info.customFormQuestions.length > 0) && (
                        <RequestRegistrationModal
                          trigger={requestRegistrationModal}
                          setTrigger={setRequestRegistrationModal}
                          info={info}
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {(info.requireApproval && info.capacity > 0 && info.approvedAttendees[registeredIndex].length >= info.capacity) ||
                    (!info.requireApproval && info.capacity > 0 && info.registered[registeredIndex].length >= info.capacity) ? (
                    <button
                      className="passed-button"
                      disabled
                      title="Event has reached capacity"
                    >
                      {/* {isStripe ? "Sold Out" : "Event Full"} */}
                      Sold Out
                    </button>
                  ) : (
                    <button
                      className="register-btn"
                      onClick={() => {
                        setButtonSignin(true);
                      }}
                    >
                      Register for this Event
                    </button>
                  )}
                  <Signin trigger={buttonSignin} setTrigger={setButtonSignin} />
                  <OrganizerSignIn trigger={organizerSignin} setTrigger={setOrganizerSignin} />
                </>
              )}
            </>


          </div>

          <div class="event-shell-info">
            <div class="event-shell-date">
              <CalendarOutlined className="calendar-icon" />
              <p class="event-shell-datetime">{formattedDate}</p>
              {/* <div class="es-datetime">
                <p class="event-shell-datetime">{formattedDate}</p>
                <p class="event-shell-datetime"> {formattedTime}</p>                
              </div> */}
            </div>

            <div class="event-shell-time">
              <ClockCircleOutlined className="clock-icon" />
              <p>{formattedTime}</p>
            </div>

            <div className="event-shell-location">
              {console.log(info, "this is the virtual link")}

              {info.hideLocation && (!isRegistered || (info.requireApproval || !isApproved)) ? (
                <div className="es-location-link">
                  <MapPin className="location-icon" />
                  <p>Register to view location</p>
                </div>
              ) : (info.location && info.location !== '') ? (
                <a className="es-location-link"
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(info.location)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MapPin className="location-icon" />
                  <p>{info.location}</p>
                </a>
              ) : (info.virtual_link && info.virtual_link !== '') ? (
                <a className="es-location-link"
                  href={info.virtual_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LaptopOutlined className="location-icon" />
                  <p>{info.virtual_link}</p>
                </a>
              ) : (
                <div className="no-location">
                  <Location className="location-icon" />
                  <p>No location given, check organizer's site.</p>
                </div>
              )}
            </div>


            <div className="event-shell-description">
              <div dangerouslySetInnerHTML={{ __html: info.description }} />
            </div>

            <div className="badge-grp bdgrp-evsh">
              {info.frequency && info.frequency !== 'Once' && (
                <div className="badge time-badge">
                  <p>{capitalizeFirstLetter(info.frequency)}</p>
                </div>
              )}

              {info.age_range && info.age_range !== 'all' && (
                <div className="badge age-badge">
                  <p>{capitalizeFirstLetter(info.age_range)}</p>
                </div>
              )}

              {info.gender && info.gender !== ('all' || '') && (
                <div className="badge gender-badge">
                  <p>{capitalizeFirstLetter(info.gender)}</p>
                </div>
              )}

              <div className="badge price-badge">
                <p>{formatPrice(info.price)}</p>
              </div>

              {info.tags.map((tag, index) => (
                <div key={index} className="badge tag-badge">
                  <p>{tag}</p>
                </div>
              ))}
            </div>

          </div>
          <div class="attendees-share">

            {(isAdmin || selectedOrganization === info.organization || info.creator === loggedInEmail.data) && (
              <AttendeesModal
                isVisible={isModalVisible}
                onCancel={handleModalCancel}
                info={info}
                dateIndex={dateIndex}
                getAttendeeDisplay={getAttendeeDisplay}
                approvedAttendees={approvedAttendees}
                onApprovalChange={onAttendeeApprovalChange}
                registeredIndex={registeredIndex}
                handleRegistrationApproval={handleRegistrationApproval}
              />
            )}


            <ConfigProvider theme={theme}>
              <Tooltip title="Only organizers can see attendees">
                <Button className="capacity-attendees" onClick={handleInputClick}>
                  {(!('hideCapacity' in info) || info.hideCapacity === false) || (isAdmin || selectedOrganization === info.organization || info.creator === loggedInEmail.data) ? (
                    <div className="attendees-number-div">
                      <Users className="attendees-icon" />
                      {getAttendeeDisplay(info, registeredIndex)}
                    </div>
                  ) : info.hideCapacity ? (
                    <div className="attendees-number-div">
                      <Users className="attendees-icon" />
                      Hidden
                    </div>
                  ) : null}
                </Button>
              </Tooltip>
            </ConfigProvider>

            {user && (isAdmin || selectedOrganization === info.organization) ? (
              <>
                <TicketScanner
                  info={info}
                  registeredIndex={registeredIndex}
                  fromQR={scannerFromQR}
                  qrUserId={qrUserId}
                  isOpen={isTickerScannerOpen}
                  onOpen={handleOpenTicketScanner}
                  onClose={handleCloseTicketScanner}
                />
              </>
            ) : (
              <ShareButton
                buttontext="Copy Link"
                styling="es-share-button"
                iconstyling="share-button-icon"
              />
            )}
          </div>
          <div class="rect5">
            <GoogleMap address={(info.hideLocation && !isRegistered) ? info.location.substring(info.location.indexOf(',') + 2) : info.location} />
          </div>

          <div class="eventshell-edit-event">
            {user ? (
              <>
                {(isAdmin || selectedOrganization === info.organization) ? (
                  <>
                    <button
                      className="already-registered-btn"
                      onClick={() => {
                        setOrgEditEvent(true)
                      }}
                    >
                      Edit Event Details
                    </button>

                    <OrgEditEvent info={info} trigger={orgEditEvent} setTrigger={setOrgEditEvent} />
                  </>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </div>


      <Footer> </Footer>
    </>
  );



};

export default EventShell;