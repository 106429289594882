import React from "react";
import { ReactComponent as PlusIcon } from './PlusIcon.svg';

function AddCard(props) {

    return (
        <>
            <a href={props.link} className="add-card" target={props.target} rel="noopener noreferrer">
                
                <div className="left">
                    <PlusIcon className="plus-icon" />
                </div>
                <div className="right">
                    <p className="ac-title">{props.title}</p>
                    <p className="ac-desc">{props.description}</p>
                    <p className="ac-tag">{props.tagline}</p>        
                </div>
            </a>
        </>
    );
}

export default AddCard;
