// GenderSelector.jsx
import React, { useState } from 'react';

const GenderSelector = ({ onChange, defaultValue }) => {
  const [selectedGender, setSelectedGender] = useState(defaultValue || null);
  const genderOptions = ['Male', 'Female'];

  const toggleGender = (gender) => {
    const newSelectedGender = selectedGender === gender ? null : gender;
    setSelectedGender(newSelectedGender);
    if (onChange) {
      onChange(newSelectedGender);
    }
  };

  return (
    <div className="gender-selector-container">
      <p className="gender-selector-title">
        Gender <span className="optional-text">(optional)</span>
      </p>
      <div className="gender-options-container">
        {genderOptions.map(gender => (
          <div
            key={gender}
            onClick={() => toggleGender(gender)}
            className={`gender-option ${selectedGender === gender ? 'selected' : ''}`}
          >
            {gender}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GenderSelector;