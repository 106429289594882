import React, { useState } from 'react';
import axios from 'axios';

function InstagramPostForm({ accessToken }) {
    const [file, setFile] = useState(null);
    const [caption, setCaption] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            alert('Please select an image file');
            return;
        }

        const formData = new FormData();
        formData.append('image', file);
        formData.append('caption', caption);
        formData.append('accessToken', accessToken);

        try {
            const response = await axios.post('http://localhost:4000/api/postToInstagram', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const data = response.data;
            if (data.success) {
                alert('Posted to Instagram successfully!');
                setFile(null);
                setCaption('');
            } else {
                throw new Error(data.error || 'Unknown error occurred');
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // Axios-specific error handling
                const errorMessage = error.response?.data?.error || error.message;
                alert('Failed to post to Instagram: ' + errorMessage);
            } else {
                // Generic error handling
                alert('Failed to post to Instagram: ' + error.message);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="file"
                accept="image/*"
                onChange={(e) => setFile(e.target.files[0])}
            />
            <textarea
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
                placeholder="Caption"
            />
            <button type="submit">Post to Instagram</button>
        </form>
    );
}

export default InstagramPostForm;